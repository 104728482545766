import * as React from 'react';
import {
  ConfirmModal,
  Field,
  Input,
  Text,
  useStyles2,
  useTheme2
} from '@grafana/ui';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { CustomCard } from 'components/Shared/CustomCard';
import { TableData } from 'types/state';
import { getSectionsStyles } from './sectionsStyles';
import { MsgMapOpcodes, buildPrivateSettingsValue, private_settings_model } from '../../../utils/settings';
import { getClientInfo, getPrivateSettingsSentByUser } from 'services/getQueries';
import { fetchPrivateSettings, influxRequest, triggerSettingsExpedition } from 'services/datasourcesRequest';
import { AppContext } from 'components/SimplePanel';
import { NotificationError, NotificationSuccess } from 'components/Shared';
import { getBackendSrv } from '@grafana/runtime';
import { getStoreSettingsEndpoint } from 'utils/helpers';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { dateTimeFormatISO } from '@grafana/data';

/** Settings card */

type SettingsProps = {
  settings: TableData['settings'];
  canEdit: boolean;
  macAddress: string;
};

export const Settings: React.FunctionComponent<SettingsProps> = ({ settings, canEdit, macAddress }) => {
  const { appState, setAppState } = React.useContext(AppContext);
  const { datasources, dashboards } = appState;
  const { rpm_max, rpm_min, rawValue, receptionDate } = settings;
  const { handleSubmit, reset, formState: { errors, defaultValues }, control, setValue, getValues, setError, clearErrors } = useForm({
    defaultValues: { rpm_min, rpm_max },
  });


  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [settingsSentByUser, setSettingsSentByUser] = React.useState({ userMinValue: '', userMaxValue: '', sentDate: '', userRawValue: '' });
  const [isSettingReceived, setIsSettingReceived] = React.useState(false);
  const [form, setForm] = React.useState({} as Omit<SettingsProps['settings'], 'hasSettings' | 'rawValue' | 'receptionDate'>);

  const { t } = useTranslation();
  const theme = useTheme2();
  const styles = useStyles2(getSectionsStyles);
  const STORE_SETTINGS_ENDPOINT = getStoreSettingsEndpoint(datasources.dashGen.uid);

  const onSubmit: SubmitHandler<Omit<SettingsProps['settings'], 'hasSettings' | 'rawValue' | 'receptionDate'>> = (data) => {
    // Check if form has been modified
    if ((defaultValues?.rpm_max === data.rpm_max) && (defaultValues?.rpm_min === data.rpm_min)) {
      NotificationError({ message: t('errors.settings_not_modified') });
      return;
    }

    if (isEditing) {
      setForm(data);
      setIsModalOpen(true);
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const validateValues = (
    inputName: 'rpm_max' | 'rpm_min',
    e: string,
  ) => {
    const inputValue = Number(e);
    const rpmMinFormValue = Number(getValues('rpm_min'));
    const rpmMaxFormValue = Number(getValues('rpm_max'));

    clearErrors(inputName);
    setValue(inputName, inputValue.toString());

    // RPM MAX
    if (inputName === 'rpm_max') {
      // Check max !<= min
      if (inputValue <= rpmMinFormValue) {
        setError(inputName, {
          type: 'custom',
          message: t('errors.max_superior_to_min'),
        });
        return;
      }
      if (rpmMinFormValue < inputValue) {
        clearErrors('rpm_min');
      }
    }

    // RPM MIN
    if (inputName === 'rpm_min') {
      // Check max <= min
      if (inputValue >= rpmMaxFormValue) {
        setError(inputName, {
          type: 'custom',
          message: t('errors.min_inferior_to_max'),
        });
        return;
      }

      if (rpmMaxFormValue > inputValue) {
        clearErrors('rpm_max');
      }
    }

    const allMax = { ...private_settings_model };
    if (inputValue > allMax[inputName]?.max!) {
      setError(inputName, {
        type: 'custom',
        message: `${inputName} ${t('errors.must_be')} < ${allMax[inputName].max!}`,
      });
      return;
    }
  };

  const saveSettings = async () => {
    const clientInfoRequest = getClientInfo(macAddress);
    const clientResult = await influxRequest({ name: datasources.influx.name, id: datasources.influx.id }, clientInfoRequest);
    if (!clientResult?.length && !clientResult[0]?.values) {
      console.error('Error getting client and gw from Batt_Lvl');
      NotificationError(t('errors.gw_client_not_found'));
      return;
    }
    const client = clientResult[0].values[0][2];
    const gw = clientResult[0].values[0][3];

    const COMMAND = MsgMapOpcodes.RX_PRV_SETTINGS_UPDATE;
    const privateSettingsToSend = buildPrivateSettingsValue(form, rawValue);
    const settingsPayload = {
      client: client,
      mac_address: macAddress,
      command: COMMAND,
      gateway: gw,
      settings: privateSettingsToSend?.toUpperCase(),
    };

    await getBackendSrv()
      .post(STORE_SETTINGS_ENDPOINT, settingsPayload, {
        responseType: 'text',
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        triggerSettingsExpedition(client, gw, macAddress, datasources.dashGen);
        // Update appState
      })
      .then(() => {
        const dashboardIndex = dashboards?.findIndex(dash => dash.macAddress === macAddress);
        if (dashboardIndex !== -1) {
          const newState = cloneDeep(appState);
          newState.dashboards[dashboardIndex].settings.rpm_min = form.rpm_min;
          newState.dashboards[dashboardIndex].settings.rpm_max = form.rpm_max;
          setAppState(newState);
          // Close modal and switch isEditing to false
          setIsModalOpen(false);
          setIsEditing(false);
          NotificationSuccess(t('success.settings_updated'));
        }
      })
      .catch((err: any) => {
        console.log(err);
        NotificationError(err);
        // Close modal and switch isEditing to false
        setIsModalOpen(false);
        setIsEditing(false);
      });
  };

  const getLastSettingsSentByUser = async () => {
    if (datasources.influx.id) {
      const settingsUserQuery = getPrivateSettingsSentByUser(macAddress);
      const {
        rpmMax,
        rpmMin,
        settingReceptionDate,
        rawValue: sentUserValue } = await fetchPrivateSettings(settingsUserQuery, datasources.influx);

      setSettingsSentByUser({
        userMaxValue: rpmMax,
        userMinValue: rpmMin,
        userRawValue: sentUserValue,
        sentDate: settingReceptionDate
      });

      if (sentUserValue?.toUpperCase() === rawValue?.toUpperCase()) {
        setIsSettingReceived(true);
      }
    }
  };

  React.useEffect(() => {
    if (!isEditing) {
      reset(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  React.useEffect(() => {
    getLastSettingsSentByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
      style={{ border: canEdit && settings.hasSettings ? `1px solid ${theme.colors.border.weak}` : '' }}>
      <CustomCard
        cardTitle={t('sections.settings.title')}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        canEdit={canEdit && settings.hasSettings}
        disabled={!!errors.rpm_max || !!errors.rpm_min}
      >
        {/* No settings */}
        {!settings.hasSettings && <Text>{t('errors.settings_not_found')}</Text>}
        {/* Read-only */}
        {!isEditing && settings.hasSettings && <>
          {(!isSettingReceived && settingsSentByUser.sentDate) &&
            <div className={styles.infoCardSmall}>
              <div style={{ fontWeight: 500 }}>{`${t('sections.settings.user_settings_not_received')} ${dateTimeFormatISO(settingsSentByUser.sentDate)}.`}</div>
              <div>RPM min: {settingsSentByUser.userMinValue} / RPM max: {settingsSentByUser.userMaxValue}</div>
            </div>}
          {(!isSettingReceived && !settingsSentByUser.sentDate) &&
            <div className={styles.infoCardSmall}>
              <div style={{ fontWeight: 500 }}>{t('sections.settings.default_settings')}</div>
            </div>}
          {receptionDate && <Field label='' description={t('sections.settings.last_reception_date')}>
            <Text weight='medium'>{dateTimeFormatISO(receptionDate)}</Text>
          </Field>}
          <Field label='' description="RPM min">
            <Text weight='medium'>{rpm_min}</Text>
          </Field>
          <Field label='' description="RPM max">
            <Text weight='medium'>{rpm_max}</Text>
          </Field>
        </>}
        {/* Edition */}
        {isEditing && settings.hasSettings && <>
          {/* RPM min */}
          <Field
            label=''
            description="RPM min"
            invalid={!!errors.rpm_min}
            error={errors?.rpm_min?.message}
          >
            <Controller
              control={control}
              name='rpm_min'
              rules={{
                required: true,
              }}
              render={({ field }) => (<Input
                type="number"
                {...field}
                onChange={(e) =>
                  validateValues('rpm_min', e.currentTarget.value)
                }
              />)}
            />
          </Field>
          {/* RPM max */}
          <Field
            label=''
            description="RPM max"
            invalid={!!errors.rpm_max}
            error={errors?.rpm_max?.message}
          >
            <Controller
              control={control}
              name='rpm_max'
              rules={{
                required: true,
              }}
              render={({ field }) => (<Input
                type="number"
                {...field}
                onChange={(e) =>
                  validateValues('rpm_max', e.currentTarget.value)
                }
              />)}
            />
          </Field>
          {/* Confirmation modal */}
          <ConfirmModal
            isOpen={isModalOpen}
            title={t('modals.settings.title')}
            body={t('modals.settings.text')}
            confirmText={t('buttons.update')}
            icon="exclamation-triangle"
            onConfirm={() => saveSettings()}
            onDismiss={() => onCancel()}
          />
        </>}
      </CustomCard>
    </form>
  );
};
