import * as React from 'react';
import { Button, Text, useStyles2 } from '@grafana/ui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

type BannerSectionProps = {
  canEdit: boolean;
  title: string;
  isEditing?: boolean;
  setIsEditing?: (value: boolean) => void;
  disabled?: boolean;
};

const getBannerSectionStyles = () => ({
  container: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
  }),
});

export const BannerSection: React.FunctionComponent<BannerSectionProps> = ({ title, isEditing, setIsEditing, canEdit, disabled }) => {
  const styles = useStyles2(getBannerSectionStyles);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Text element='h6' weight='bold'>{title}</Text>
      {canEdit && <div>
        {isEditing &&
          (<div>
            <Button
              type='submit'
              icon='check'
              disabled={disabled}
              aria-label='validate changes'
              size='sm'
              variant='primary'
              fill='outline'
              style={{ marginRight: '4px' }}
            >
              {t('buttons.save')}
            </Button>
            <Button
              icon='times'
              type='button'
              aria-label='cancel changes'
              onClick={() => setIsEditing && setIsEditing(false)}
              size='sm'
              variant='secondary'
              fill='outline'>
              {t('buttons.cancel')}
            </Button>
          </div>)}
        {!isEditing && (<div>
          <Button
            icon='edit'
            type='button'
            aria-label='edit'
            onClick={() => setIsEditing && setIsEditing(true)} size='sm' variant='primary' fill='text'>
            {t('buttons.edit')}
          </Button>
        </div>)}
      </div>}
    </div>
  );
};
