import * as React from 'react';
import { Alert, Button, Modal, useStyles2 } from '@grafana/ui';
import { useTranslation } from 'react-i18next';
import { ParametersProps } from './NoTraining';
import { Session } from './FormSections/Session';
import { CustomCard, NotificationError, NotificationSuccess } from 'components/Shared';
import { AppContext } from 'components/SimplePanel';
import { cloneDeep } from 'lodash';
import { TRAINING_STATUS } from 'types/state';
import { getStyles } from './cardStyles';
import { mysqlRequest } from 'services/datasourcesRequest';
import { getBackendSrv } from '@grafana/runtime';
import { AI_SESSION_STATUS, URL_POST_DATASOURCE_UID } from 'utils/constants';
import { getLastSessionId } from 'services/getQueries';

/** Component displayed if session status is READY */

export const Ready: React.FunctionComponent<ParametersProps> = ({ rowOriginal }) => {
  const { appState, setAppState } = React.useContext(AppContext);
  const { dashboards, datasources } = appState;

  const { training, macAddress } = rowOriginal;
  const [isConfirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const styles = useStyles2(getStyles);
  const { t } = useTranslation();

  const WRITE_API_URL = `${URL_POST_DATASOURCE_UID}${datasources.dashGen.uid}`;
  const AI_SESSION_STATUS_ENDPOINT = `${WRITE_API_URL}${AI_SESSION_STATUS}`;

  // Request to invalidate the last session
  // Do the msql request first and then change status in app state
  const handleConfirmation = async () => {
    const aiSessionIdQuery = getLastSessionId(macAddress);
    const aiSessionIdResults = await mysqlRequest(datasources.mysql.uid, aiSessionIdQuery)
      .catch((err) => {
        NotificationError(err);
        return;
      });

    if (!aiSessionIdResults) {
      NotificationError({ message: t('errors.last_session_not_found') });
      return;
    }

    if (aiSessionIdResults.length) {
      if (aiSessionIdResults[0][0] !== training.sessionId) {
        NotificationError({ message: t('errors.new_session_created') });
        return;
      }

      const aiPayload = {
        mac_address: macAddress,
        ai_session_id: aiSessionIdResults[0][0],
        new_status: TRAINING_STATUS.Invalidated,
      };

      await getBackendSrv()
        .put(AI_SESSION_STATUS_ENDPOINT, aiPayload, {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          const dashboardIndex = dashboards?.findIndex(dash => dash.macAddress === macAddress);
          if (dashboardIndex !== -1) {
            const newState = cloneDeep(appState);
            newState.dashboards[dashboardIndex].training.status = TRAINING_STATUS.Invalidated;
            newState.dashboards[dashboardIndex].training.sessionId = aiSessionIdResults[0][0];
            newState.dashboards[dashboardIndex].training.performedDate = '';
            newState.dashboards[dashboardIndex].training.convergingEndDate = '';
            newState.dashboards[dashboardIndex].training.type = '';
            setAppState(newState);
          }
          setConfirmModalOpen(false);
          NotificationSuccess(t('success.session_invalidated'));
        })
        .catch((err) => {
          NotificationError(err);
          console.log(err);
        });
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* Info */}
      <div style={{ maxWidth: '280px', marginRight: '48px' }}>
        <Alert title={t('cards_info.ready.title')} severity={'success'}>
          <div>{t('cards_info.ready.text1')}</div>
          <div>{t('cards_info.ready.text2')}</div>
        </Alert>
      </div>
      {/* Session */}
      <Session session={training} macAddress={macAddress} />
      <div className={styles.container}>
        {/** Invalidate session */}
        <CustomCard cardTitle={t('sections.actions.title')} canEdit={false}>
          <Button
            onClick={() => setConfirmModalOpen(true)}
            icon='times'
            variant='secondary'
            fill='outline'
          >{t('buttons.invalidate_session')}</Button>
        </CustomCard>
        {/* Confirmation modal */}
        <Modal
          title={t('modals.invalidate_session.title')}
          isOpen={isConfirmModalOpen}
          onDismiss={() => setConfirmModalOpen(false)}
        >
          <div>{t('modals.invalidate_session.text')} {macAddress} ?</div>
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={() => {
              setConfirmModalOpen(false);
            }}>
              {t('buttons.cancel')}
            </Button>
            <Button variant='destructive' onClick={() => handleConfirmation()}>
              {t('buttons.invalidate_session')}
            </Button>
          </Modal.ButtonRow>
        </Modal>
      </div>
    </div>
  );
}

