import { SelectableValue } from "@grafana/data";
import { TRAINING_STATUS, TrainingStatus } from "types/state";
import i18n from '../components/i18n';
import { STORE_SETTINGS, URL_POST_DATASOURCE_UID } from "./constants";
import { BadgeColor } from "@grafana/ui";

export const getIconNameStatus = (status: string) => {
  let iconName;
  let description;
  let color = '' as BadgeColor;

  const t = i18n.t;
  const sessionStatus = status?.includes('ERR_') ? 'ERROR' : status;

  switch (sessionStatus) {
    case 'NO TRAINING':
      iconName = 'exclamation-circle';
      description = 'No session found';
      color = 'orange';
      break;
    case 'INVALIDATED':
      iconName = 'times';
      description = 'La session a été invalidée pour une des raisons suivantes : Changement majeur de version de modèle, le modèle en base n\'a pas été correctement entraîné, le modèle en base a été entraîné avec une stratégie différente de la stratégie courante, l\'orientation de la balise a été modifiée';
      color = 'red';
      break;
    case 'CONVERGING':
      iconName = 'asserts';
      description = 'Converging';
      color = 'blue';
      break;
    case 'READY':
      iconName = 'check';
      description = t('cards_info.ready.title');
      color = 'green';
      break;
    case 'TRAINING':
      iconName = 'process';
      description = t('statuses.training.text1');
      color = 'blue';
      break;
    case 'ERROR':
      iconName = 'exclamation-triangle';
      color = 'red';
      description = 'Error';
      break;
    case 'PENDING':
      iconName = 'hour-glass';
      description = t('statuses.pending.text1');
      color = 'blue';
      break;
    default:
      break;
  }
  return { iconName, description, color };
};

export const getPageSizeOptions = (): SelectableValue[] => {
  const options = [5, 10, 20, 30, 50, 100];
  return options.map(option => ({ label: option.toString(), value: option }));
};

export const getStoreSettingsEndpoint = (datasourceUid: string) => {
  if (!datasourceUid) {
    return '';
  }
  // endpoint
  const datasourceUrl = `${URL_POST_DATASOURCE_UID}${datasourceUid}`;
  const endpoint = `${datasourceUrl}${STORE_SETTINGS}`;
  return endpoint;
};

export const getErrorDetails = (status: TrainingStatus) => {
  const t = i18n.t;

  let message = '';

  switch (status) {
    case TRAINING_STATUS.ErrAdvisor:
      message = t('statuses.errors.adv_score');
      break;
    case TRAINING_STATUS.ErrScore:
      message = t('statuses.errors.adv_score');
      break;
    case TRAINING_STATUS.ErrFetchPickle:
      message = t('statuses.errors.fetch_pickle');
      break;
    case TRAINING_STATUS.ErrInternal:
      message = t('statuses.errors.internal');
      break;
    case TRAINING_STATUS.ErrModelFit:
      message = t('statuses.errors.model_fit');
      break;
    case TRAINING_STATUS.ErrParam:
      message = t('statuses.errors.err_param');
      break;
    case TRAINING_STATUS.ErrTrainingData:
      message = t('statuses.errors.training_data');
      break;
    case TRAINING_STATUS.ErrStorePickle:
      message = t('statuses.errors.store_pickle');
      break;
    default:
      break;
  }
  return message;
};

export const getFormattedIds = (idTrainingZonesResult: any[]) => {
  let zonesId = '';
  idTrainingZonesResult.map((id: any) => (zonesId += `${id[0]}, `));
  const ids = zonesId.slice(0, -2); // remove comma & white space
  return ids;
};
