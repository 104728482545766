import React, { useMemo } from 'react';
import { TrainingTable } from './Table';
import { Badge, BadgeColor, IconButton, TextLink, useStyles2 } from '@grafana/ui';
import { getStyles } from './tableStyles';
import { ColumnDef } from '@tanstack/react-table';
import { getIconNameStatus } from 'utils/helpers';
import { AppContext } from 'components/SimplePanel';
import { TableData } from 'types/state';
import i18n from '../../components/i18n';

export const TableContainer = () => {
  const styles = useStyles2(getStyles);
  const { appState } = React.useContext(AppContext);
  const { dashboards } = appState;
  const t = i18n.t;

  const data: TableData[] = useMemo(
    () => {
      if (dashboards?.length) {
        return dashboards;
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.dashboards]);

  const columns = useMemo<Array<ColumnDef<TableData>>>(
    () => [
      {
        id: "expander",
        header: ({ table }) => (
          <IconButton aria-label='Expand row' size="lg" name={table.getIsAllRowsExpanded() ? 'angle-double-down' : 'angle-double-right'}
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          />
        ),
        cell: ({ row }) => {
          return (<div className={styles.expandedContentRow}>
            <IconButton aria-label='Expand row' size="lg" name={row.getIsExpanded() ? 'angle-down' : 'angle-right'}
              {...{
                onClick: row.getToggleExpandedHandler(),
              }}
            />
          </div>);
        },
      },
      {
        header: t('table.folder'),
        accessorKey: "folder",
        meta: {
          filterVariant: 'select',
        },
      },
      {
        header: t('table.dashboard'),
        accessorKey: "dashboardTitle",
        cell: ({ getValue, cell }) => {
          return (cell.row.original.dashboardUrl ?
            (<TextLink href={cell.row.original.dashboardUrl} color="primary" inline={false} icon='link' external>
              {getValue<string>()}
            </TextLink>) : getValue<string>());
        }
      },
      {
        header: t('table.mac_address'),
        accessorKey: "macAddress",
      },
      {
        id: "trainingStatus",
        header: t('table.training_status'),
        accessorKey: "training.status",
        meta: {
          filterVariant: 'select',
        },
        cell: ({ getValue }) => {
          const { color } = getIconNameStatus(getValue<string>());
          return (<span>{<Badge color={color as BadgeColor} text={getValue<string>()} />}</span>);
        }
      },
      {
        id: "trainingType",
        header: t('table.training_type'),
        accessorKey: "training.type",
        meta: {
          filterVariant: 'select',
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <TrainingTable data={data} columns={columns} getRowCanExpand={() => true} />
  );
};
