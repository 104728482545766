export const TOTAL_POINTS_LIMIT = 7000;

// Datasources
export const INFLUX_DB = 'asystom_db';
export const DASH_GEN = 'DashGen';
export const MYSQL = 'mysql_datasource';

// API
export const API_DASHBOARD_BY_UID = 'api/dashboards/uid/';
export const API_QUERY_DASHBOARD = 'api/search?query=';
export const API_QUERY_DS = 'api/ds/query';
export const API_SAVE_JSON = 'api/dashboards/db';
export const API_SEARCH_DASHBOARDS = `${API_QUERY_DASHBOARD}&type=dash-db&tag=Machine`;
export const URL_GET_DATASOURCE_BY_NAME = 'api/datasources/name/';
export const URL_POST_DATASOURCE = 'api/datasources/proxy/';
export const URL_POST_DATASOURCE_UID = `${URL_POST_DATASOURCE}uid/`;

// endpoints
export const STORE_SETTINGS = '/store_settings';
export const LORAWAN_DL = '/lorawanDl';
export const AUTO_TRAINING_SESSION = '/auto_training_session';
export const AI_SESSION_STATUS = '/ai_session_status';
export const IDENTITY_CARD = '/identity_card';
// JSON paths
export const URL_ISO_JSON = 'public/asystom/iso-classifications/classifications.json';
export const DASHBOARD_JSON_MODEL = '/public/asystom/asystom-dashboards/dashboard_';
export const DASHBOARD_VALVE_JSON_MODEL = '/public/asystom/asystom-dashboards/dashboard_valve_';

// Settings
export const SETTINGS_OFFSET = 8; // remove sensor type and orientation frame


// Dashboard
export const INCIDENT_ADVISOR_PANEL = 'asystom-ad-ia';

export const CALC_FAHRENHEIT = '* 9/5 + 32'; // convert temperature - celsius / fahrenheit
export const CALC_INCH = '/ 25.4'; // convert length - meter / inch
