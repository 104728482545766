import React from 'react';
import {
  Alert,
  Button,
  Form,
  HorizontalGroup,
  InlineField,
  InlineFieldRow,
  InlineSwitch,
  useStyles2,
  useTheme2,
} from '@grafana/ui';

import { getStyles } from '../getSectionsStyles';
import { AppContext } from 'components/SimplePanel';
import { SubSectionActivation } from './SubSectionActivation';
import { useFormPublicSettings } from 'hooks/useFormPublicSettings';
import { SectionReboot } from './SectionReboot';
import { ConfirmationModal } from '../ConfirmationModal';
import { SubSectionAmbientPeriod } from './SubSectionAmbientPeriod';
import { SubSectionPredictionPeriod } from './SubSectionPredictionPeriod';
import { SubSectionIntrospectionPeriod } from './SubSectionIntrospectionPeriod';
import { OrgRole } from '@grafana/data';
import { MsgMapOpcodes } from 'utils/settings_models';
import { getReceptionDate } from 'utils/helpers';

/**
 * Scheduling and activation section
 * @returns ambient period field
 * @returns prediction period field
 * @returns sync field
 */

interface SectionPublicSettingsProps {
  setIsMainModalOpen: (value: boolean) => void;
}

export type RequestSettingsType = 'reboot' | 'send' | 'request';

export const SectionPublicSettings: React.FunctionComponent<SectionPublicSettingsProps> = ({ setIsMainModalOpen }) => {
  const { dico, isVersionFound, user, beacon, datasource } = React.useContext(AppContext);
  const [receptionDate, setReceptionDate] = React.useState('');

  const {
    defaultSync,
    hexString,
    handleConfirmModal,
    handlePeriodChange,
    handleSwitch,
    isSync,
    setIsSync,
    setSettingsForm,
    setShowConfirmModalSend,
    settings,
    showConfirmModalSend,
    sendSpecialCommand,
    sendPublicSettings,
  } = useFormPublicSettings(setIsMainModalOpen);

  // Translations
  const {
    activation,
    resetText,
    sendSettingsModalTitle1,
    submitSettings,
    subtitleScheduling,
    titleScheduling,
  } = dico?.dico || {};

  const theme = useTheme2();
  const styles = useStyles2(() => getStyles(theme));

  React.useEffect(() => {
    const fetchReceptionDate = async () => {
      const reception = await getReceptionDate(
        'SettingsPublicBackup',
        beacon,
        datasource,
        dico.dico.reception,
        dico.dico.settingsNotReceived
      );
      setReceptionDate(reception);
    };
    if (beacon && datasource.influx) {
      fetchReceptionDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beacon, datasource]);

  return (
    <div className={styles.sectionContainer}>
      {isVersionFound && (
        <>
          {/* Special commands */}
          <SectionReboot setIsMainModalOpen={setIsMainModalOpen} />
          {/* Scheduling and activation */}
          <div className={styles.section}>
            <Form
              style={{
                width: '100%',
                maxWidth: '750px',
              }}
              onSubmit={(form) => setSettingsForm(form)}
              validateOn={'onChange'}
              defaultValues={{
                ...settings?.public,
                ...settings?.indicators,
              }}>
              {({ control, setValue, getValues, reset, errors, setError, clearErrors, register }) => (
                <>
                  {/* Settings reception date */}
                  <h4>{titleScheduling?.toUpperCase()}</h4>
                  {receptionDate && (
                    <div className={styles.receptionDate}>
                      {receptionDate}
                    </div>
                  )}
                  {/* Activation */}
                  {user.orgRole === OrgRole.Admin && (
                    <>
                      <h5>{activation}</h5>
                      <SubSectionActivation register={register} defaultValues={settings?.indicators} />
                    </>
                  )}
                  {/* Schedulings */}
                  <h5>{subtitleScheduling}</h5>
                  {/* Ambient period */}
                  <SubSectionAmbientPeriod
                    clearErrors={clearErrors}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    setError={setError}
                    setValue={setValue}
                    dico={dico.dico}
                    setIsMainModalOpen={setIsMainModalOpen}
                    handlePeriodChange={handlePeriodChange}
                  />
                  {/* Prediction period */}
                  <SubSectionPredictionPeriod
                    clearErrors={clearErrors}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    setError={setError}
                    setValue={setValue}
                    dico={dico.dico}
                    setIsMainModalOpen={setIsMainModalOpen}
                    handlePeriodChange={handlePeriodChange}
                    isSync={isSync}
                  />
                  {/* Sync */}
                  <InlineFieldRow>
                    <InlineField label={'Sync'} labelWidth={24} htmlFor="sync">
                      <InlineSwitch
                        value={isSync}
                        id={'sync'}
                        onChange={() => handleSwitch(setValue, getValues, setError)}
                      />
                    </InlineField>
                  </InlineFieldRow>
                  {user.orgRole === OrgRole.Admin && (
                    <>
                      {/* Introspection period */}
                      <SubSectionIntrospectionPeriod
                        clearErrors={clearErrors}
                        control={control}
                        errors={errors}
                        getValues={getValues}
                        setError={setError}
                        setValue={setValue}
                        dico={dico.dico}
                        setIsMainModalOpen={setIsMainModalOpen}
                        handlePeriodChange={handlePeriodChange}
                      />
                    </>
                  )}
                  {/* Submit */}
                  <div className={styles.submit}>
                    <HorizontalGroup spacing={'sm'} justify={'flex-start'} align={'center'}>
                      <Button
                        type="reset"
                        variant={'secondary'}
                        fill={'outline'}
                        onClick={() => {
                          reset();
                          setIsSync(defaultSync);
                        }}>
                        {resetText}
                      </Button>
                      <Button
                        type="submit"
                        disabled={!!Object.keys(errors).length}
                        onClick={() => handleConfirmModal('send')}>
                        {submitSettings}
                      </Button>
                      <ConfirmationModal
                        setShowConfirmModal={setShowConfirmModalSend}
                        showConfirmModal={showConfirmModalSend}
                        hexString={`${hexString}:${MsgMapOpcodes.RX_PUB_SETTINGS_UPDATE}`}
                        confirmSettings={sendPublicSettings}
                        modalTitle={sendSettingsModalTitle1}
                      />
                    </HorizontalGroup>
                  </div>
                </>
              )}
            </Form>
          </div>
        </>
      )}
      {!isVersionFound && <CardActionRequestSettings sendSpecialCommand={sendSpecialCommand} />}
    </div>
  );
};

interface CardActionRequestSettingsProps {
  sendSpecialCommand: (value: RequestSettingsType) => void;
}

const CardActionRequestSettings: React.FunctionComponent<CardActionRequestSettingsProps> = ({ sendSpecialCommand }) => {
  const { dico, beacon } = React.useContext(AppContext);
  const { noDevice, noSettingsFor, requestSettingsMessage, requestSettingsButton } = dico?.dico || {};

  return (
    <Alert
      title={noDevice}
      severity={'warning'}
      buttonContent={<span>{requestSettingsButton}</span>}
      onRemove={() => sendSpecialCommand('request')}>
      <>
        <div>
          {noSettingsFor} {beacon}.
        </div>
        <div>{requestSettingsMessage}</div>
      </>
    </Alert>
  );
};
