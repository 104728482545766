import { NotificationError } from "components/Shared";

interface SettingProp {
  sizeC: number;
  valueOffset: number;
  isBitmask: boolean;
  max?: number;
  bitPos?: number;
  mask?: number;
  enabled?: boolean;
  selected?: string;
}

export type PrivateSettingsKeys =
  | 'rpm_max'
  | 'rpm_min'
  ;
export type PrivateSettingsModel = Record<PrivateSettingsKeys, SettingProp>;

export const private_settings_model: PrivateSettingsModel = {
  rpm_max: {
    isBitmask: false,
    sizeC: 4,
    valueOffset: 16,
    max: 65535,
  },
  rpm_min: {
    isBitmask: false,
    sizeC: 4,
    valueOffset: 20,
    max: 65535,
  }
};

/** Decode micro_freq_max | micro_freq_min | accelero_freq_max | accelero_freq_min | rpm_max | rpm_min | threshold | learning_steps | custom_spectrum_type | custom_spectrum_param */
export const decodeBasicSettings = (settingValue: string) => {
  let privateSettings = {} as any;
  for (const [setting, value] of Object.entries({
    ...private_settings_model,
  })) {
    if (setting.match(/^rpm_/g)) {
      let tmp = decodeToUint32(settingValue.substring(value.valueOffset, value.valueOffset + value.sizeC));
      tmp *= 60; // Convert RPM as remote sends Hz
      privateSettings[setting as keyof PrivateSettingsKeys] = tmp;
    }
  }
  return privateSettings;
};

export const decodeToUint32 = (value: string) => {
  if (!value.length || value.length > 8) {
    console.log('error while decoding settings');
    return 0;
  }
  const matchedValue = value.match(/../g);
  if (matchedValue) {
    const decodedValue = matchedValue.reverse().join('');
    return parseInt('0x' + decodedValue, 16);
  }

  console.log('error while decoding settings');
  return 0;
};

export const MsgMapOpcodes = {
  RX_PRV_SETTINGS_UPDATE: 71,
};

export const replaceAt = (settingValue: string, value: string, valueOffset: number, valueSize: number) => {
  return settingValue.slice(0, valueOffset) + value + settingValue.slice(valueOffset + valueSize, settingValue.length);
};

export const convertToHex16b = (n: number): string => {
  let valueToConvert = n;
  if (typeof n === 'string') {
    valueToConvert = Number(n);
  }
  const convertedValue = (valueToConvert + Math.pow(2, 16)).toString(16).match(/\B../g)!;
  return convertedValue.reverse().join(``);
};

const PRIVATE_SETTINGS_LENGTH = 76;

type SettingsProps = {
  rpm_max: string; rpm_min: string;
};

export const buildPrivateSettingsValue = (settingsForm: SettingsProps, rawValue: string) => {
  if (!rawValue) {
    NotificationError({ message: 'No settings raw value' });
    return;
  }

  let privateSettingsBuiltvalue = rawValue;

  for (const [setting, settingValue] of Object.entries(private_settings_model)) {
    const key = setting as keyof PrivateSettingsModel;
    const tmp = Math.floor(parseInt(settingsForm[key], 10) / 60);

    const valueOffset = settingValue.valueOffset;
    const sizeC = settingValue.sizeC;
    const convertedValue = convertToHex16b(tmp);
    privateSettingsBuiltvalue = replaceAt(privateSettingsBuiltvalue, convertedValue, valueOffset, sizeC);
  }

  if (privateSettingsBuiltvalue.length !== PRIVATE_SETTINGS_LENGTH) {
    NotificationError({ message: 'Encoded settings value incorrect' });
    return;
  }

  return privateSettingsBuiltvalue;
};
