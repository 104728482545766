import { Field, FieldType, getActiveThreshold, getDisplayProcessor, ThresholdsMode } from '@grafana/data';
import { Gauge, useTheme2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { ThresholdColors } from 'constants/colors';
import { getValue } from 'helpers/panel-data';
import React, { useEffect, useState } from 'react';

interface GaugeProps {
  decimals: number;
  gaugeThresholds: number[];
  max: number;
  title: string;
  unit: string;
}

export const GaugeIndicator: React.FunctionComponent<GaugeProps> = ({
  decimals,
  gaugeThresholds,
  max,
  title,
  unit,
}) => {
  const { state } = useAppState();
  const { panelData, panelOptions, dico } = state;
  const [boundaries, setBoundaries] = useState([] as any);
  const gaugeValue = getValue(title, panelData, dico['no-data'], decimals);
  const theme = useTheme2();

  const field: Partial<Field> = {
    type: FieldType.number,
    config: {
      color: { mode: 'thresholds' },
      min: 0,
      max: max,
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          { value: -Infinity, color: ThresholdColors.OK },
          {
            value: typeof boundaries[0] === 'string' ? parseFloat(boundaries[0]) : boundaries[0],
            color: ThresholdColors.MEDIUM,
          },
          {
            value: typeof boundaries[1] === 'string' ? parseFloat(boundaries[1]) : boundaries[1],
            color: ThresholdColors.DANGER,
          },
        ],
      },
    },
  };

  field.display = getDisplayProcessor({ field, theme });

  const activeThreshold = getActiveThreshold(gaugeValue as number, field.config?.thresholds?.steps as any);

  const props = React.useMemo(() => {
    return {
      theme,
      width: 290,
      height: 210,
      value: {
        text: `${gaugeValue}` as string,
        title: unit,
        numeric: gaugeValue as number,
        color: activeThreshold.color,
      },
      field: field.config,
      display: field.display,
    };
  }, [boundaries, field]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gaugeThresholds) {
      setBoundaries([gaugeThresholds[0], gaugeThresholds[1]]);
    }
  }, [gaugeThresholds, panelOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Gauge {...props} showThresholdMarkers showThresholdLabels />
    </>
  );
};
