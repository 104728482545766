import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';

const appEvents = getAppEvents();

export const NotificationSuccess = (message: string) => {
  appEvents.publish({
    type: AppEvents.alertSuccess.name,
    payload: ['Success', message],
  });
};

export const NotificationError = (err?: any) => {
  appEvents.publish({
    type: AppEvents.alertError.name,
    payload: [err.status ? `Error status ${err.status}` : 'Error', err.data ? err.data?.message : err.message],
  });
};

export const NotificationWarning = (message: string) => {
  appEvents.publish({
    type: AppEvents.alertWarning.name,
    payload: ['Warning', message],
  });
};

