import React, { Dispatch } from 'react';
import { GrafanaTheme2, PanelProps } from '@grafana/data';
import { config, RefreshEvent, getTemplateSrv } from '@grafana/runtime';
import { LoadingBar, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { AppState, TRAINING_STATUS } from 'types/state';
import { SimpleOptions } from 'types';
import { useDatasource } from 'hooks/useDatasource';
import { useDictionary } from 'hooks/useDictionary';
import { useFetchDashboards } from 'hooks/useFetchDashboards';
import { HeaderContainer } from './Header/HeaderContainer';
import { TableContainer } from './Table/TableContainer';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';

interface AppContextType {
  appState: AppState;
  setAppState: Dispatch<React.SetStateAction<AppState>>;
}

export const AppContext = React.createContext({} as AppContextType);

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      overflow: 'auto'
    }),
    tableContainer: css({
      border: `1px solid ${theme.colors.border.weak}`,
      width: "95%",
      height: "100%",
    })
  };
};

export const SimplePanel: React.FunctionComponent<Props> = ({ options, data, width, height, fieldConfig, id, eventBus }) => {
  const lang = getTemplateSrv().replace('$lang_selection');
  const styles = useStyles2(getStyles);

  const datasources = useDatasource();
  const { isoClassOptions, isoClassifications } = useDictionary(lang);
  const { dashboards, loading, refetch } = useFetchDashboards(datasources.mysql.uid, { id: datasources.influx.id, name: datasources.influx.name }, lang);
  const [overview, setOverview] = React.useState({
    totalDashboards: 0,
    invalidated: 0,
    ready: 0,
    converging: 0,
    pending: 0,
    training: 0,
    error: 0,
    noTraining: 0
  });
  // Context state
  const [appState, setAppState] = React.useState({
    panelData: data,
    datasources: datasources,
    user: config.bootData.user,
    lang: lang,
    dico: isoClassOptions,
    isoClassifications: isoClassifications,
    dashboards: dashboards,
    loadingDashboards: loading,
  });

  // Listen refresh event
  React.useEffect(() => {
    const subscriber = eventBus.getStream(RefreshEvent).subscribe(event => {
      if (datasources?.mysql?.uid) {
        // refecth dashboards
        refetch(datasources?.mysql?.uid, { name: datasources.influx.name, id: datasources.influx.id });
      }
    });

    return () => {
      subscriber.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventBus, datasources]);

  React.useEffect(() => {
    if (dashboards?.length && lang) {
      setAppState({
        ...appState,
        dashboards: dashboards,
        datasources: datasources,
        dico: isoClassOptions,
        isoClassifications: isoClassifications,
        loadingDashboards: loading,
        lang: lang
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, datasources, isoClassOptions, dashboards]);

  React.useEffect(() => {
    // Apply lang
    if (lang) {
      i18next.changeLanguage(lang?.toLowerCase()).catch((err) => {
        console.error('Error changing language:', err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  React.useEffect(() => {
    if (dashboards?.length) {
      const total = dashboards?.length;
      const totalReady = dashboards.filter(dashboard => dashboard.training.status === TRAINING_STATUS.Ready);
      const totalInvalidated = dashboards.filter(dashboard => dashboard.training.status === TRAINING_STATUS.Invalidated);
      const totalPending = dashboards.filter(dashboard => dashboard.training.status === TRAINING_STATUS.Pending);
      const totalTraining = dashboards.filter(dashboard => dashboard.training.status === TRAINING_STATUS.Training);
      const totalConverging = dashboards.filter(dashboard => dashboard.training.status === TRAINING_STATUS.Converging);
      const totalNoTraining = dashboards.filter(dashboard => dashboard.training.status === TRAINING_STATUS.NoTraining);
      const totalError = dashboards.filter(dashboard => dashboard.training.status?.includes('ERR_'));
      setOverview({
        totalDashboards: total,
        ready: totalReady?.length,
        invalidated: totalInvalidated?.length,
        converging: totalConverging?.length,
        training: totalTraining?.length,
        noTraining: totalNoTraining?.length,
        error: totalError?.length,
        pending: totalPending?.length
      });
    }
  }, [dashboards]);

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      <I18nextProvider i18n={i18next}>
        <div className={styles.wrapper} style={{ height: `${height}px` }}>
          <HeaderContainer overview={overview} />
          <div className={styles.tableContainer}>
            {loading && <LoadingBar width={400} />}
            {!loading && <TableContainer />}
          </div>
        </div>
      </I18nextProvider>
    </AppContext.Provider>
  );
};
