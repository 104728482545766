/** Get ai session */
export const getAiSessionData = (macAddress: string) => {
  return `SELECT id, performed, type, status FROM ai_session WHERE mac_address='${macAddress}' ORDER BY id DESC LIMIT 1;`;
};

/** Get identity card */
export const getIdentityCardData = (dashboardTitle: string, macAddress: string) => {
  return `SELECT machine_name, mac_address, analysis_profile, process_function, class, sensor_orientation FROM identity_card WHERE machine_name='${dashboardTitle}' AND mac_address='${macAddress}';`;
};

/** Get private settings */
export const getPrivateSettings = (macAddress: string) => {
  return `SELECT value FROM SettingsPrivateBackup WHERE \"command\"=~/71|73/ AND \"device\"='${macAddress}' AND version != '' ORDER BY time DESC LIMIT 1`;
};

/** Get private settings */
export const getPrivateSettingsSentByUser = (macAddress: string) => {
  return `SELECT value FROM SettingsPrivateBackup WHERE \"command\"=~/71|73/ AND \"device\"='${macAddress}' AND version = '' ORDER BY time DESC LIMIT 1`;
};


/** Get training zones */
export const getTrainingZones = (macAddress: string, ids: string) => {
  return `SELECT id, start_date, end_date FROM ai_training_set WHERE mac_address = '${macAddress}' AND id IN (${ids});`;
};

/** Get client info */
export const getClientInfo = (macAddress: string) => {
  return `SELECT last(value), client, GW FROM "Batt_Lvl" WHERE \"device\"='${macAddress}'`;
};

export const getIdTrainingSetQuery = (macAddress: string, currentSessionId: number) => {
  return `SELECT id_training_set FROM ai_train_rel WHERE id_session = ${currentSessionId} AND mac_address = '${macAddress}';`;
};

export const getLastSessionId = (macAddress: string) => {
  return `SELECT id FROM ai_session WHERE mac_address ="${macAddress}" ORDER BY id DESC LIMIT 1;`;
};

export const getAiTrainingPerformed = (sessionId: number) => {
  return `SELECT performed, status FROM ai_training WHERE session=${sessionId} ORDER BY session DESC LIMIT 1;`;
};

export const getCountPoints = (macAddress: string, performed: string) => {
  return `SELECT COUNT(aux_f0) FROM Signature_Anomaly WHERE \"device\"='${macAddress}' AND time > ${performed}ms`;
};
