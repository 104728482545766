import { PanelData, getProcessedDataFrames, AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { FFT_SETTINGS_LENGTH, SIGNATURE_SETTINGS } from 'utils/constants';
import { FftFormDTO, SensorType } from 'types/form';
import { decodeToUint32, decodeToUint8 } from 'utils/decode';
import { TranslationProps } from 'types/dictionary';

export const decodeSettingsFtt = (settingsValue: string) => {
  const settings: FftFormDTO = {
    handle: decodeToUint8(settingsValue.substring(0, 2)),
    activation: decodeToUint8(settingsValue.substring(2, 4)),
    steps: decodeToUint8(settingsValue.substring(4, 6)),
    algorithm: decodeToUint8(settingsValue.substring(6, 8)),
    fft_sensor_type: decodeToUint8(settingsValue.substring(8, 10)).toString(10) as SensorType,
    fft_sensor_orientation: decodeToUint8(settingsValue.substring(10, 12)),
    fft_freq_max: decodeToUint32(settingsValue.substring(12, 20)).toString(10), // param 1
    fft_freq_min: decodeToUint32(settingsValue.substring(20, 28)).toString(10), // param2
    fft_compression: decodeToUint32(settingsValue.substring(28, 36)), // param 3
    fft_spectr_type: decodeToUint32(settingsValue.substring(36, 44)).toString(10), // custom spectrogram param 4
    fft_cutoff: decodeToUint32(settingsValue.substring(44, 52)).toString(10), // custom spectrogram param 5
  };

  if (settings['activation'] === 2 && settings['steps'] === 0) {
    settings['steps'] = 1; // burst is finished, reset to 1 (minimum)
  }
  /** Get permanent mode from 1/n mode */
  if (settings['activation'] === 1 && settings['steps'] === 1) {
    settings['activation'] = 11; // Permanent
  }

  /** Set spectrum custom filter value to 200Hz */
  if (settings['fft_cutoff'] === '0' && (settings['fft_spectr_type'] === '5' || settings['fft_spectr_type'] === '6')) {
    settings['fft_cutoff'] = '200';
  }

  return settings;
};

/**
 * Get signature settings (refId B in panel query)
 * to decode FFT ZOOM settings
 *
 * handle | activation | steps | algorithm | sensor | orientation |
 * param_1 (upper freq) | param_2 (lower freq) | param_3 (compression) | param_4 (custom spectrum type) | param_5 (custom spectrum param: cutoff value)
 */

export const useSettingsFft = (panelData: PanelData, dico: TranslationProps) => {
  const { errorFftSettingsLength, fftZoomSettingsNotFound } = dico;

  const getSettings = () => {
    const appEvents = getAppEvents();
    const series = getProcessedDataFrames(panelData?.series);
    const signatureSettings = series.filter((d) => d.name === SIGNATURE_SETTINGS);

    if (!signatureSettings?.length) {
      appEvents.publish({
        type: AppEvents.alertWarning.name,
        payload: [fftZoomSettingsNotFound],
      });
      return;
    }

    const settingsFft = signatureSettings[0]?.fields[1]?.values?.get(0);

    if (settingsFft?.length !== FFT_SETTINGS_LENGTH) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [errorFftSettingsLength],
      });
      return;
    }

    const decodedSettings = decodeSettingsFtt(settingsFft);

    return {
      raw: settingsFft,
      display: decodedSettings,
    };
  };

  const settingsFft = getSettings();
  return settingsFft;
};
