/** MYSQL QUERIES */

/**
 * @requestType mysql select
 * @table ai_session
 * @columns id, status
 * @conditions mac_address
 */

export const getSessionQuery = (macAddress: string) =>
  `SELECT id, status FROM ai_session WHERE mac_address = '${macAddress}' ORDER BY id DESC;`;

/**
 * @requestType mysql select
 * @table ai_session
 * @columns id, performed, model_hyperparameters, description, status
 * @conditions mac_address
 */

export const getAiSessionDetailsQuery = (macAddress: string) =>
  `SELECT id, performed,model_hyperparameters,description, status, type FROM ai_session WHERE mac_address = '${macAddress}' ORDER BY id DESC`;

/**
 * @requestType mysql select
 * @table ai_session
 * @columns model_hyperparameters, model, id
 * @conditions mac_address, status='READY'
 */

export const getAiSessionDetailsStatus = (macAddress: string) =>
  `SELECT model_hyperparameters, model, id, status FROM ai_session WHERE mac_address = '${macAddress}' ORDER BY id DESC LIMIT 1`;

/**
 * @requestType mysql select
 * @table ai_session
 * @columns model_hyperparameters, model, id
 * @conditions mac_address,
 */

export const getLastSettingsQuery = (macAddress: string) =>
  `SELECT id, model_hyperparameters FROM ai_session WHERE mac_address = '${macAddress}' ORDER BY id DESC LIMIT 1`;

/**
 * @requestType mysql select
 * @table ai_train_rel
 * @columns id_training_set
 * @conditions mac_address, id_session = sessionId
 */

export const getSessionTrainingSetQuery = (macAddress: string, sessionId: number) =>
  `SELECT id_training_set FROM ai_train_rel WHERE mac_address = '${macAddress}' AND id_session = ${sessionId};`;

/**
 * @requestType mysql select
 * @table ai_training_set
 * @columns id, start_date, end_date
 * @conditions mac_address, id IN ids
 */

export const getTrainingSetDetailsQuery = (macAddress: string, ids: string) =>
  `SELECT id, start_date, end_date FROM ai_training_set WHERE mac_address = '${macAddress}' AND id IN (${ids});`;

/**
 * @requestType mysql select
 * @table ai_train_rel
 * @columns id_training_set
 * @conditions mac_address, id_session = currentSessionId
 */

export const getIdTrainingSetQuery = (macAddress: string, currentSessionId: number) =>
  `SELECT id_training_set FROM ai_train_rel WHERE id_session = ${currentSessionId} AND mac_address = '${macAddress}';`;

/** INFLUX QUERY */

/**
 * @measurement Signature
 * @fiels s_00, sonic_rmslog
 * @query Machine activity, group by time
 */

export const getMachineActivityGroupedQuery = (
  macAddress: string,
  from: number,
  to: number,
  groupSize: number,
  timeZone: string
) =>
  `SELECT percentile(machine_activity, 90) FROM (SELECT ("s_00" \%2B\ "sonic_rmslog")/2 AS machine_activity FROM "Signature" WHERE ("device" = '${macAddress}') AND time > ${from}ms and time < ${to}ms) GROUP BY time(${groupSize}m) fill(none) tz('${timeZone}')`;

/**
 * @measurement Signature
 * @fiels s_00, sonic_rmslog
 */

export const getMachineActivityQuery = (macAddress: string, from: string, to: string, timeZone: string) =>
  `SELECT machine_activity FROM (SELECT ("s_00" \%2B\ "sonic_rmslog")/2 AS machine_activity FROM "Signature" WHERE device='${macAddress}' AND time >'${from}' AND time<'${to}') ORDER BY time ASC tz('${timeZone}')`;

/**
 * @measurement Signature
 * @fiels sonic_rmslog
 */

export const getSonicRmsQuery = (macAddress: string, now: number, nowMinusSixMonths: number) =>
  `SELECT percentile("sonic_rmslog", 90) FROM "Signature" WHERE ("device" ='${macAddress}') AND time < ${now}ms AND time > ${nowMinusSixMonths}ms GROUP BY time(5m) fill(null)`;

export const getVersionQuery = (macAddress: string) =>
  `SELECT value, version FROM "SettingsPublicBackup" WHERE "device" = '${macAddress}' AND version !='' ORDER BY time DESC LIMIT 1`;
