import { getBackendSrv } from '@grafana/runtime';
import { API_SEARCH_DASHBOARDS, API_DASHBOARD_BY_UID, INCIDENT_ADVISOR_PANEL } from '../utils/constants';
import { fetchPrivateSettings, mysqlRequest } from 'services/datasourcesRequest';
import { NotificationError } from 'components/Shared/Notification';
import { getAiSessionData, getIdentityCardData, getPrivateSettings } from './getQueries';
import { AutoTrainingStatus, SESSION_TYPE, TRAINING_STATUS, TableData, TrainingStatus } from 'types/state';
import { t } from 'i18next';
import { getTranslatedValue } from 'utils/dashboard';

/**
 * Get all the dashboards from Grafana
 * @returns dashboards names flatted
 */

export const getDashboardsList = async (dsMysqlUid: string, dsInflux: { id: number, name: string; }, lang: string) => {

  const grafanaDashboards = await getBackendSrv()
    .get(API_SEARCH_DASHBOARDS)
    .catch((error: any) => {
      console.log(error);
      NotificationError(error);
    });

  if (!Array.isArray(grafanaDashboards)) {
    return;
  }

  /**
   * Filter dashboards in mysql database that don't exist in Grafana.
   * - Avoid update of dashboards being deleted in Grafana but still in database.
   * - Avoid update of dashboards related to another organization.
   */

  const filteredDashboards = [] as TableData[];
  for (const dashboard of grafanaDashboards) {
    await getBackendSrv()
      .get(`${API_DASHBOARD_BY_UID}${dashboard.uid}`)
      .then(async (result) => {
        const templating = result?.dashboard.templating.list;
        if (!templating) {
          return;
        }
        // Check if lang is the same as the org lang
        const dashboardLang = templating?.find(
          (template: { name: string; }) => template.name === 'lang_selection'
        );

        if (dashboardLang?.query !== lang) {
          console.log(`The dashboard '${result?.dashboard.title}' has not the same language as the organization and can not be updated here.`);
          return;
        }
        // Get mac_address template
        const dashboardMacAddress = templating?.find(
          (template: { name: string; }) => template.name === 'beacon_selection'
        );
        if (!dashboardMacAddress) {
          NotificationError({ message: `Can not get mac address from the dashboard ${result?.dashboard.title}` });
          return;
        }
        const macAddress = dashboardMacAddress.query;

        // Get length unit
        let lengthUnit = 'meter';
        const dashboardLengthUnit = templating?.find(
          (template: { name: string; }) => template.name === 'length_unit'
        );

        if (dashboardLengthUnit?.query) {
          lengthUnit = dashboardLengthUnit.query;
        }

        // Request identity_card
        // machine_name, mac_address, analysis_profile, process_function, class, sensor_orientation
        const idCardQuery = getIdentityCardData(dashboard.title, macAddress);
        const idCardResult = await mysqlRequest(dsMysqlUid, idCardQuery);
        if (!idCardResult?.length) {
          return;
        }

        let customIsoValues = { alarm: '', trip: '' };
        if (idCardResult[0][4] === t('classifications.custom_class')) {

          const incidentAdvisorPanelIndex = result.dashboard?.panels?.findIndex((panel: { type: string; }) => panel?.type === INCIDENT_ADVISOR_PANEL);
          if (incidentAdvisorPanelIndex !== -1) {
            const findCustomIso = result.dashboard.panels[incidentAdvisorPanelIndex].idCardElements.findIndex((element: { field: string; }) => element.field === 'customIso');
            if (findCustomIso !== -1) {
              customIsoValues = {
                alarm: result.dashboard.panels[incidentAdvisorPanelIndex].idCardElements[findCustomIso].value[0],
                trip: result.dashboard.panels[incidentAdvisorPanelIndex].idCardElements[findCustomIso].value[1]
              };
            }
          }
        }

        // Request ai_session
        // id, performed, type, status
        const aiSessionQuery = getAiSessionData(macAddress);
        const aiSessionResult = await mysqlRequest(dsMysqlUid, aiSessionQuery);

        let sessionStatus = '';
        let sessionType = '';
        let sessionPerformedDate = '';
        let sessionId = null;

        if (aiSessionResult?.length) {
          sessionId = aiSessionResult[0][0];
          sessionPerformedDate = aiSessionResult[0][1];
          sessionType = aiSessionResult[0][2] === SESSION_TYPE.Kapacitor ? 'MANUAL_TRAIN' : aiSessionResult[0][2];
          sessionStatus = aiSessionResult[0][3];
        }

        // Request private settings to get RPM
        const settingsQuery = getPrivateSettings(macAddress);
        const { rpmMax, rpmMin, settingReceptionDate, rawValue } = await fetchPrivateSettings(settingsQuery, dsInflux);
        let rpmMinValue = rpmMin;
        let rpmMaxValue = rpmMax;
        let settingsValue = rawValue;

        // Translate id card values
        let analysisProfile = idCardResult[0][2];
        let processFunction = idCardResult[0][3];
        let isoClass = idCardResult[0][4];

        if (dashboardLang.query !== 'EN') {
          analysisProfile = getTranslatedValue(analysisProfile, 'analysis_profile');
          processFunction = getTranslatedValue(processFunction, 'process_function');
          isoClass = getTranslatedValue(isoClass, 'class');
        }

        filteredDashboards.push({
          folderId: result?.meta.folderId,
          folder: result?.meta.folderTitle,
          dashboardUid: dashboard.uid,
          dashboardLang: dashboardLang.query,
          dashboardUrl: result?.meta.url,
          dashboardTitle: idCardResult[0][0],
          macAddress: idCardResult[0][1],
          lengthUnit: lengthUnit as 'meter' | 'inch',
          identityCard: {
            analysisProfile: analysisProfile,
            processFunction: processFunction,
            isoClass: isoClass,
            sensorOrientation: idCardResult[0][5],
            customIso: customIsoValues
          },
          training: {
            status: sessionStatus ? sessionStatus as TrainingStatus : TRAINING_STATUS.NoTraining,
            type: sessionType,
            performedDate: sessionPerformedDate,
            convergingEndDate: '',
            autoTrainingStatus: '' as AutoTrainingStatus,
            sessionId: sessionId
          },
          settings: {
            rpm_min: rpmMinValue,
            rpm_max: rpmMaxValue,
            hasSettings: !!rpmMinValue && !!rpmMaxValue,
            rawValue: settingsValue,
            receptionDate: settingReceptionDate
          }
        });
      })
      .catch((error: any) => {
        console.log(error);
        NotificationError(error);
        return;
      });
  }
  return filteredDashboards.flat();
};
