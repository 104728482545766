import { ThresholdColors } from 'constants/colors';
import { ThresholdsAnomalyScore } from 'constants/thresholds';
import { ClassProps, IdElementProps, IsoClassificationsProps } from 'app-context/types';
import { TranslationProps } from 'translation';

export const getVibrationThresholds = (
  classValue: string,
  classProps: ClassProps,
  lengthUnit: string,
  isoClassifications: IsoClassificationsProps
) => {
  const { vibrationSeverityIsoIns, vibrationSeverityIsoMms } = isoClassifications;
  let thresholds = [] as any;
  const currentClass = classValue?.toUpperCase();
  const UNIT = lengthUnit === 'inch' ? 'inch' : 'meter';

  switch (true) {
    // iso 10816
    case currentClass === classProps.class1?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.class1 : vibrationSeverityIsoIns.class1;
      break;
    case currentClass === classProps.class2?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.class2 : vibrationSeverityIsoIns.class2;
      break;
    case currentClass === classProps.class3?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.class3 : vibrationSeverityIsoIns.class3;
      break;
    case currentClass === classProps.class4?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.class4 : vibrationSeverityIsoIns.class4;
      break;

    // iso 20816
    case currentClass === classProps.A1?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.a1 : vibrationSeverityIsoIns.a1;
      break;
    case currentClass === classProps.A2?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.a2 : vibrationSeverityIsoIns.a2;
      break;
    case currentClass === classProps.A3?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.a3 : vibrationSeverityIsoIns.a3;
      break;
    case currentClass === classProps.A4?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.a4 : vibrationSeverityIsoIns.a4;
      break;
    case currentClass === classProps.B1?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.b1 : vibrationSeverityIsoIns.b1;
      break;
    case currentClass === classProps.B2?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.b2 : vibrationSeverityIsoIns.b2;
      break;
    case currentClass === classProps.B3?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.b3 : vibrationSeverityIsoIns.b3;
      break;
    case currentClass === classProps.B4?.toUpperCase():
      thresholds = UNIT === 'meter' ? vibrationSeverityIsoMms.b4 : vibrationSeverityIsoIns.b4;
      break;
    // note : custom thresholds are handled previously in getClassRanges()
    default:
      break;
  }
  return thresholds;
};

export const getAnomalyScoreColor = (score: number) => {
  let colorScore = '';
  if (!score) {
    colorScore = ThresholdColors.NONE;
    return colorScore;
  }
  switch (true) {
    case score > ThresholdsAnomalyScore.BAD:
      colorScore = ThresholdColors.DANGER;
      break;
    case score > ThresholdsAnomalyScore.MEDIUM:
      colorScore = ThresholdColors.MEDIUM;
      break;
    default:
      colorScore = ThresholdColors.OK;
      break;
  }
  return colorScore;
};

export const getAlertColorStatus = (alertState: string) => {
  let color = '';

  switch (alertState) {
    case 'no_data':
      color = ThresholdColors.MEDIUM;
      break;
    case 'ok':
      color = ThresholdColors.OK;
      break;
    case 'alerting':
      color = ThresholdColors.DANGER;
      break;
    default:
      color = ThresholdColors.NONE;
  }
  return color;
};

export const getColorSessionStatus = (status: string) => {
  let fillColor = '';
  switch (status) {
    case 'READY':
      fillColor = ThresholdColors.OK;
      break;
    case 'TRAINING':
      fillColor = ThresholdColors.MEDIUM;
      break;
    case 'PENDING':
      fillColor = ThresholdColors.NONE;
      break;
    case 'CONVERGING':
      fillColor = '#3e71cd';
      break;
    // all the rest (errors)
    default:
      fillColor = ThresholdColors.DANGER;
      break;
  }

  return fillColor;
};

export const getClassRanges = (
  identityCard: IdElementProps[],
  dico: TranslationProps,
  lengthUnit: string,
  isoClassifications: IsoClassificationsProps
) => {
  const indexClass = identityCard.findIndex(
    (idElement) => idElement?.name?.toUpperCase() === dico.class?.toUpperCase()
  );
  if (indexClass === -1) {
    return;
  }
  const translatedClasses = {} as ClassProps;
  translatedClasses.class1 = dico['identity-card']['Class I'];
  translatedClasses.class2 = dico['identity-card']['Class II'];
  translatedClasses.class3 = dico['identity-card']['Class III'];
  translatedClasses.class4 = dico['identity-card']['Class IV'];
  translatedClasses.A1 = dico['identity-card']['A1'];
  translatedClasses.A2 = dico['identity-card']['A2'];
  translatedClasses.A3 = dico['identity-card']['A3'];
  translatedClasses.A4 = dico['identity-card']['A4'];
  translatedClasses.B1 = dico['identity-card']['B1'];
  translatedClasses.B2 = dico['identity-card']['B2'];
  translatedClasses.B3 = dico['identity-card']['B3'];
  translatedClasses.B4 = dico['identity-card']['B4'];
  translatedClasses.customClass = dico['identity-card']['customClass'];

  // Handle custom thresolds
  if (identityCard[indexClass].value === dico['identity-card']['customClass']) {
    const customIsoIndex = identityCard.findIndex((field) => field.name === 'customIso');
    if (customIsoIndex !== -1) {
      return { alarm: identityCard[customIsoIndex].value[0], trip: identityCard[customIsoIndex].value[1] };
    }
  }

  let classRange = getVibrationThresholds(
    identityCard[indexClass].value,
    translatedClasses,
    lengthUnit,
    isoClassifications
  );

  return classRange;
};
