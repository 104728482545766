import { css } from "@emotion/css";
import { GrafanaTheme2 } from "@grafana/data";

export const getStyles = (theme: GrafanaTheme2) => {
  const rowHoverBg = theme.colors.emphasize(theme.colors.background.primary, 0.03);

  return {
    container: css({
      display: 'flex',
      gap: theme.spacing(2),
      flexDirection: 'column',
      width: '100%',
      height: '550px',
      overflow: 'auto'
    }),

    table: css({
      borderRadius: theme.shape.radius.default,
      width: '100%',
      td: {
        padding: theme.spacing(1),
        borderBottom: "1px solid #ddd",
      },
      'thead th': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        borderBottom: `1px solid ${theme.colors.border.weak}`,
      },

      'td, th': {
        minWidth: theme.spacing(3),
      },
    }),

    disableGrow: css({
      width: 0,
    }),

    header: css({
      borderBottom: `1px solid ${theme.colors.border.weak}`,
      backgroundColor: `${theme.colors.background.primary}`,
      '&, & > button': {
        position: 'relative',
        whiteSpace: 'nowrap',
        padding: theme.spacing(1),
      },
      '& > button': {
        '&:after': {
          content: '"\\00a0"',
        },
        width: '100%',
        height: '100%',
        background: 'none',
        border: 'none',
        paddingRight: theme.spacing(2.5),
        textAlign: 'left',
        fontWeight: theme.typography.fontWeightMedium,
      },
    }),
    row: css({
      label: 'row',
      borderBottom: `1px solid ${theme.colors.border.weak}`,

      '&:hover': {
        backgroundColor: rowHoverBg,
      },

      '&:last-child': {
        borderBottom: 0,
      },
    }),
    expandedRow: css({
      label: 'expanded-row-content',
      borderBottom: 'none',
      backgroundColor: rowHoverBg,
    }),
    expandedContentRow: css({
      label: 'expanded-row-content',
      td: {
        borderBottom: `1px solid ${theme.colors.border.weak}`,
        position: 'relative',
        padding: theme.spacing(2, 2, 2, 5),

        '&:before': {
          content: '""',
          position: 'absolute',
          width: '1px',
          top: 0,
          left: '16px',
          bottom: theme.spacing(2),
          background: theme.colors.border.medium,
        },
      },
    }),
    sortableHeader: css({
      /* increases selector's specificity so that it always takes precedence over default styles  */
      '&&': {
        padding: 0,
      },
    }),
  };
};
