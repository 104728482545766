import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { BannerSection } from 'components/Shared/BannerSection';
import * as React from 'react';


const getSectionsStyles = (theme: GrafanaTheme2) => ({
  separator: css({
    borderBottom: `1px solid ${theme.colors.border.weak}`
  }),
  content: css({
    padding: '16px',
    minHeight: '280px'
  }),
  icon: css({
    marginRight: '8px',
  }),
});

interface CustomCardProps {
  cardTitle: string;
  canEdit: boolean;
  children: React.ReactNode;
  isEditing?: boolean;
  setIsEditing?: (value: boolean) => void;
  disabled?: boolean;
};

export const CustomCard: React.FunctionComponent<CustomCardProps> = ({
  cardTitle,
  children,
  canEdit,
  isEditing,
  setIsEditing,
  disabled,
}) => {
  const styles = useStyles2(getSectionsStyles);

  return (
    <React.Fragment>
      <div className={styles.separator}>
        <BannerSection title={cardTitle} canEdit={canEdit} isEditing={isEditing} setIsEditing={setIsEditing} disabled={disabled} />
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </React.Fragment>
  );
};
