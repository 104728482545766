export interface DashboardProps {
  dashboard: {
    title: string;
    tags: string[];
    templating: {
      list: any[];
    };
    panels: any[];
    idCardElements: any[];
  };
  overwrite: boolean;
  folderId: number;
}

export enum TemplatesVar {
  beacon = 'beacon_selection',
  image = 'imageFile',
  lang = 'lang_selection',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  lengthUnit = 'length_unit',
}

interface ObjOrientation {
  value: string;
  increment: number;
}

export interface Orientation {
  X: ObjOrientation;
  Y: ObjOrientation;
  Z: ObjOrientation;
}
