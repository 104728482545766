import * as React from 'react';
import { Alert, Button, Checkbox, Modal, Stack, useStyles2 } from '@grafana/ui';
import { CoreRow } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IdentityCard } from './FormSections/IdentityCard';
import { Settings } from './FormSections/Settings';
import { CustomCard, NotificationError, NotificationSuccess } from 'components/Shared';
import { SESSION_TYPE, TRAINING_STATUS, TableData } from 'types/state';
import { getBackendSrv } from '@grafana/runtime';
import { getStyles } from './cardStyles';
import { AppContext } from 'components/SimplePanel';
import { cloneDeep } from 'lodash';
import { getErrorDetails } from 'utils/helpers';
import { AUTO_TRAINING_SESSION, URL_POST_DATASOURCE_UID } from 'utils/constants';

/** Component displayed if training status is null or 'INVALIDATED' */

export interface ParametersProps {
  rowOriginal: CoreRow<TableData>['original'];
}

export const NoTraining: React.FC<ParametersProps> = ({ rowOriginal }) => {
  const { appState, setAppState } = React.useContext(AppContext);
  const { datasources, dashboards } = appState;
  const { settings, macAddress, training } = rowOriginal;

  const [isConfirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [checkboxes, setCheckboxes] = React.useState({
    checkbox1: false,
    checkbox2: false,
  });

  const areAllChecked = Object.values(checkboxes).every(Boolean);
  const styles = useStyles2(getStyles);
  const { t } = useTranslation();

  const WRITE_API_URL = `${URL_POST_DATASOURCE_UID}${datasources.dashGen.uid}`;
  const AUTO_TRAINING_SESSION_ENDPOINT = `${WRITE_API_URL}${AUTO_TRAINING_SESSION}`;

  const handleCheckboxChange = (key: keyof typeof checkboxes) => {
    setCheckboxes({ ...checkboxes, [key]: !checkboxes[key] });
  };

  const handleConfirmation = async () => {
    const autoTrainingPayload = {
      mac_address: macAddress,
    };

    await getBackendSrv()
      .post(AUTO_TRAINING_SESSION_ENDPOINT, autoTrainingPayload, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        //  Change status in app state;
        const dashboardIndex = dashboards?.findIndex(dash => dash.macAddress === macAddress);
        if (dashboardIndex !== -1) {
          const newState = cloneDeep(appState);
          newState.dashboards[dashboardIndex].training.status = TRAINING_STATUS.Converging;
          newState.dashboards[dashboardIndex].training.performedDate = resp?.performedDate;
          newState.dashboards[dashboardIndex].training.type = SESSION_TYPE.AutoTrain;
          setAppState(newState);
        }
        handleCancel();
        NotificationSuccess(t('success.auto_training_launched'));
      })
      .catch((err) => {
        NotificationError(err);
        setConfirmModalOpen(false);
      });
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
    setCheckboxes({ "checkbox1": false, "checkbox2": false });
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ maxWidth: '280px', marginRight: '48px' }}>
        {/** No training  card info */}
        {training.status === TRAINING_STATUS.NoTraining
          && <Alert title={t('cards_info.no_training.title')} severity={'warning'}>
            <div>{t('cards_info.no_training.text1')} {macAddress}.</div>
            <div>{t('cards_info.no_training.text2')}</div>
          </Alert>}
        {/** Invalidated card info */}
        {training.status === TRAINING_STATUS.Invalidated
          && <Alert title={t('cards_info.invalidated.title')} severity={'error'}>
            <div>{t('cards_info.invalidated.text1')}</div>
            <div>{t('cards_info.no_training.text2')}</div>
          </Alert>}
        {/** Error card info */}
        {training.status.includes('ERR_')
          && <Alert title={`${t('modals.session_error.title')} (${training.status})`} severity={'error'}>
            <div>{getErrorDetails(training.status)}</div>
          </Alert>}
      </div>
      {/* Identity Card section */}
      <IdentityCard
        rowOriginal={rowOriginal}
        canEdit={true}
      />
      {/* Settings */}
      <Settings settings={settings} canEdit={true} macAddress={macAddress} />
      {/* Call to action */}
      <div className={styles.container}>
        <CustomCard cardTitle={t('sections.actions.title')} canEdit={false}>
          <Button
            onClick={() => setConfirmModalOpen(true)}
            icon='play'
            variant='success'
            fill='outline'
            disabled={!settings.hasSettings}
          >{t('buttons.start_auto_training')}</Button>
        </CustomCard>
        {/* Confirmation modal */}
        <Modal
          title={`${t('modals.auto_train.title')} ${macAddress}`}
          isOpen={isConfirmModalOpen}
          onDismiss={() => handleCancel()}
        >
          <div>
            <Stack direction="column" alignItems="flex-start">
              <Checkbox
                defaultChecked={false}
                label={t('identity_card')}
                description={t('modals.id_card.check1')}
                onChange={() => handleCheckboxChange("checkbox1")} />
              <Checkbox
                defaultChecked={false}
                label={t('beacon_settings')}
                description={t('modals.id_card.check2')}
                onChange={() => handleCheckboxChange("checkbox2")} />
            </Stack>
          </div>
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={() => handleCancel()}>
              {t('buttons.cancel')}
            </Button>
            <Button
              disabled={!areAllChecked}
              variant='success' icon='play'
              onClick={() => handleConfirmation()}
            >
              {t('buttons.start_auto_training')}
            </Button>
          </Modal.ButtonRow>
        </Modal>
      </div>
    </div>
  );
};
