import * as React from 'react';
import { ParametersProps } from './NoTraining';
import { Session } from './FormSections/Session';
import { Alert } from '@grafana/ui';
import { TRAINING_STATUS } from 'types/state';
import { useTranslation } from 'react-i18next';

/** Component displayed if session status is TRAINING or PENDING */

export const Training: React.FunctionComponent<ParametersProps> = ({ rowOriginal }) => {
  const { training, macAddress } = rowOriginal;
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* Info */}
      <div style={{ maxWidth: '280px', marginRight: '48px' }}>
        {training.status === TRAINING_STATUS.Training &&
          <Alert title={"Training"} severity={'info'}>
            <div>{t('statuses.training.text1')}</div>
          </Alert>}
        {training.status === TRAINING_STATUS.Pending && <Alert title={"Pending"} severity={'info'}>
          <div>{t('statuses.pending.text1')}</div>
        </Alert>}
      </div>
      {/* Session */}
      <Session session={training} macAddress={macAddress} />
    </div>
  );
}

