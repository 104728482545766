import { Input, Select, useStyles2, Icon, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { ErrorTypes, IsoClasses, Option, TranslationLang } from 'app-context/types';
import { CLASS_TABLE } from 'helpers/constants';
import { addToForm, findIndexInForm, getDefaultValue, getPlaceholder, setError } from 'helpers/helpersForm';
import { getFormStyles } from '../getStepStyles';
import React, { useEffect } from 'react';
import { SelectableValue } from '@grafana/data';

interface IsoClassFieldsProps {
  tableName: string;
  indexFieldForm: number;
  optionsSelect: Option[];
  name: string;
  isInvalid: boolean | undefined;
}

export const IsoClassFields: React.FunctionComponent<IsoClassFieldsProps> = ({
  indexFieldForm,
  isInvalid,
  name,
  optionsSelect,
  tableName,
}) => {
  const { dispatch, state } = useAppState();
  const { customIso, dictionary_uiElements, error, form, isoClassifications, lang, lengthUnit } = state;
  const { TR_alarmDefinition, TR_classField, TR_customClass, TR_tripDefinition } = dictionary_uiElements;
  const styles = useStyles2(getFormStyles);

  // to be implemented lated
  const handleCustomIsoChange = (value: string, valueType: 'warning' | 'alert') => {
    switch (valueType) {
      case 'warning':
        dispatch({ type: 'SET_CUSTOM_ISO', payload: { alert: customIso.alert, warning: value } });
        break;
      case 'alert':
        dispatch({ type: 'SET_CUSTOM_ISO', payload: { alert: value, warning: customIso.warning } });
        break;
      default:
        break;
    }
  };

  const handleClassChange = (e: any) => {
    // Here we check that the value is not a node (we don't want a node to be selected)
    if (!e) {
      addToForm(dispatch, '', name, tableName);
      return;
    }
    addToForm(dispatch, e.value, name, tableName);
  };

  const updateDescriptions = (options: SelectableValue[]) => {
    if (!options?.length) {
      return [];
    }
    const findKey = (optionValue: string) => {
      let foundKey = '';
      for (const [key, value] of Object.entries(isoClassifications.translations[`${lang}` as TranslationLang])) {
        if (optionValue === value) {
          foundKey = key;
          return foundKey as IsoClasses;
        }
      }
      return foundKey as IsoClasses;
    };

    const newOptions = options?.map((node) => {
      node?.options?.map((option: SelectableValue) => {
        if (lengthUnit === 'inch') {
          const key = findKey(option.value);
          option.description = `Alarm: ${isoClassifications.vibrationSeverityIsoIns[key].alarm} in/s | Trip: ${isoClassifications.vibrationSeverityIsoIns[key].trip} in/s`;
        }
        if (lengthUnit === 'meter') {
          const key = findKey(option.value);
          option.description = `Alarm: ${isoClassifications.vibrationSeverityIsoMms[key].alarm} mm/s | Trip: ${isoClassifications.vibrationSeverityIsoMms[key].trip} mm/s`;
        }
        return option;
      });
      return node;
    });
    return newOptions;
  };

  useEffect(() => {
    const classIndex = findIndexInForm(TR_classField, form);
    if (classIndex === -1 || form[classIndex].value !== TR_customClass) {
      return;
    }
    // this is needed for form validation (resend the class value to test form validity)
    addToForm(dispatch, form[classIndex].value, name, tableName);

    if (
      (customIso.alert && parseFloat(customIso.alert) < 0) ||
      (customIso.warning && parseFloat(customIso.warning) < 0)
    ) {
      setError(ErrorTypes.customThresholds, 'The thresholds must be positive', dispatch);
      return;
    }

    if (customIso.alert && parseFloat(customIso.warning) >= parseFloat(customIso.alert)) {
      setError(ErrorTypes.customThresholds, 'The trip value must be superior to the alarm value', dispatch);
      return;
    }

    if (customIso.alert && parseFloat(customIso.alert) > 100) {
      setError(ErrorTypes.customThresholds, 'The trip value must be inferior to 100', dispatch);
      return;
    }

    setError(ErrorTypes.customThresholds, '', dispatch);
  }, [customIso]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const classIndex = findIndexInForm(TR_classField, form);
    if (classIndex === -1 || form[classIndex].value !== TR_customClass) {
      return;
    }
    // this is needed for form validation (resend the class value to test form validity)
    addToForm(dispatch, form[classIndex].value, name, tableName);
  }, [error.customThresholds]); // eslint-disable-line react-hooks/exhaustive-deps

  const LENGTH_UNIT = lengthUnit === 'inch' ? 'in/s' : 'mm/s'; // to be implemented later
  const OPTIONS_WITH_DESCRIPTION = React.useMemo(() => updateDescriptions(optionsSelect), [lengthUnit, optionsSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {tableName === CLASS_TABLE && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Select
            width={50}
            options={OPTIONS_WITH_DESCRIPTION ? OPTIONS_WITH_DESCRIPTION : []}
            value={getDefaultValue(indexFieldForm, form)}
            onChange={(v) => handleClassChange(v)}
            placeholder={getPlaceholder(tableName, dictionary_uiElements)}
            invalid={isInvalid}
            maxMenuHeight={240}
          />
          {/* to be implemented later */}
          {getDefaultValue(indexFieldForm, form) === TR_customClass && (
            <div style={{ display: 'flex', marginTop: '8px', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={styles.labelMin}>
                  ALARM{' '}
                  <span>
                    <Tooltip content={TR_alarmDefinition}>
                      <Icon name={'info-circle'} />
                    </Tooltip>
                  </span>
                </div>
                <Input
                  value={customIso.warning}
                  type={'number'}
                  onChange={(e) => handleCustomIsoChange(e.currentTarget.value, 'warning')}
                  width={16}
                  suffix={LENGTH_UNIT}
                  placeholder="0"
                  min={0}
                  max={100}
                  invalid={isInvalid}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                <div className={styles.labelMin}>
                  TRIP{' '}
                  <span>
                    <Tooltip content={TR_tripDefinition}>
                      <Icon name={'info-circle'} />
                    </Tooltip>
                  </span>
                </div>
                <Input
                  value={customIso.alert}
                  onChange={(e) => handleCustomIsoChange(e.currentTarget.value, 'alert')}
                  width={16}
                  type={'number'}
                  suffix={LENGTH_UNIT}
                  placeholder="0"
                  min={0}
                  max={100}
                  invalid={isInvalid}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
