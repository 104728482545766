import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../locales/en/translation.json';
import translationFr from '../locales/fr/translation.json';

i18next
  .use(initReactI18next) // Bind i18next to React
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
      fr: {
        translation: translationFr
      },
    },
    fallbackLng: 'en', // Fallback language
    ns: ['translation'],
    defaultNS: 'translation', // Default namespace
    lng: 'en', // Default language
    debug: true, // Enable debug mode for development
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18next;
