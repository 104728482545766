import * as React from 'react';

import { getBackendSrv, getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import {} from '@grafana/ui';
import { AppContext } from 'components/SimplePanel';

import { useSettingsPrivate } from 'hooks/useSettingsPrivate';
import { useVersion } from 'hooks/useVersion';
import { MsgMapOpcodes } from 'utils/settings_models';
import { buildPrivateSettingsValue, getStoreSettingsEndpoint, triggerSettingsExpedition } from 'utils/helpers';
import { SignatureFormDTO } from 'types/form';

export const useFormSectionSignatureSettings = () => {
  const { panelData, datasource, beacon, client, gateway, setIsVersionFound, isVersionFound, dico } =
    React.useContext(AppContext);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [settingsForm, setSettingsForm] = React.useState({} as SignatureFormDTO);
  const [hexString, setHexaString] = React.useState('');

  const { noClient, errorSignatureSettings, settingsEmpty } = dico?.dico || {};
  const version = useVersion(panelData);
  const canUseCustomSpectrogram = version.raw >= 4.45;
  const { settings } = useSettingsPrivate(panelData, setIsVersionFound, isVersionFound, version);

  // endpoint
  const STORE_SETTINGS_ENDPOINT = getStoreSettingsEndpoint(datasource.dashGen.uid);
  const appEvents = getAppEvents();

  /** Build Private settings value */
  const confirmSettings = () => {
    if (!Object.keys(settingsForm).length) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [settingsEmpty],
      });
      return;
    }

    if (!client || !gateway || !beacon) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [noClient],
      });
      return;
    }

    if (!hexString) {
      return;
    }
    sendSettings(hexString);
  };

  /**
   * Post settings value to SettingsQueue and then in SettingPrivateBackup
   * @param privateSettingsToSend
   */

  const sendSettings = async (privateSettingsToSend: string) => {
    const COMMAND = MsgMapOpcodes.RX_PRV_SETTINGS_UPDATE;

    const settingsPayload = {
      client: client,
      mac_address: beacon,
      command: COMMAND,
      gateway: gateway,
      settings: privateSettingsToSend?.toUpperCase(),
    };

    await getBackendSrv()
      .post(STORE_SETTINGS_ENDPOINT, settingsPayload, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        triggerSettingsExpedition(client, gateway, beacon, datasource.dashGen, dico?.dico);
      })
      .catch((err) => {
        console.log(err);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [errorSignatureSettings],
        });
      });
  };

  React.useEffect(() => {
    if (showConfirmModal && Object.keys(settingsForm)?.length) {
      const settings = buildPrivateSettingsValue(settingsForm, dico.dico);
      if (settings) {
        setHexaString(settings);
      }
    }
  }, [showConfirmModal, settingsForm, dico.dico]);

  return {
    canUseCustomSpectrogram,
    confirmSettings,
    hexString,
    setSettingsForm,
    setShowConfirmModal,
    settings,
    settingsForm,
    showConfirmModal,
  };
};
