import * as React from 'react';
import { AppEvents, PanelData, getProcessedDataFrames } from '@grafana/data';
import { AppContext } from 'components/SimplePanel';

import { PRIVATE_SETTINGS, PRIVATE_SETTINGS_LENGTH } from 'utils/constants';
import { SignatureFormDTO } from 'types/form';
import { getAppEvents } from '@grafana/runtime';
import { radio_model, woe_modes_model } from 'utils/settings_models';
import { decodeSensorPrivateSettings, decodeWoe, decodeBasicSettings, decodeRadio } from 'utils/decode';

const SVP_OFFSET = 8; // sensor type and orientation frame

/** Get private settings (refId D in panel query) and decode settings value */

export const useSettingsPrivate = (
  panelData: PanelData,
  setIsVersionFound: (value: boolean) => void,
  isVersionFound: boolean,
  version: {
    raw: number;
    displayed: string;
  }
) => {
  const { dico } = React.useContext(AppContext);
  const { privateSettingsNotFound, invalidPrivateSettings } = dico?.dico || {};

  const decodePrivateSettings = React.useCallback((settingsValue: string) => {
    const sensorSettings = decodeSensorPrivateSettings(settingsValue);
    const basicSettingsValue = settingsValue.slice(SVP_OFFSET, settingsValue.length + 1); // settings purged of sensor type & orientation values
    const basicSettings = decodeBasicSettings(basicSettingsValue);
    const woeSettingsValue = basicSettingsValue.substring(
      woe_modes_model['woe_mode'].valueOffset,
      radio_model['radio_adr'].valueOffset
    );
    const woeSettings = decodeWoe(woeSettingsValue);
    const radioSettingsValue = basicSettingsValue.substring(radio_model['radio_adr'].valueOffset, settingsValue.length);
    const radioSettings = decodeRadio(radioSettingsValue);

    const allSettings = {
      ...sensorSettings,
      ...basicSettings,
      ...woeSettings,
      ...radioSettings,
    };
    return allSettings as SignatureFormDTO;
  }, []);

  const getSettings = React.useCallback(() => {
    if (!isVersionFound) {
      return {
        settings: {} as SignatureFormDTO,
      };
    }
    const data = getProcessedDataFrames(panelData?.series);
    const settingsPrivateSerie = data.filter((d) => d.name === PRIVATE_SETTINGS);
    const appEvents = getAppEvents();

    if (!settingsPrivateSerie?.length && version.raw >= 4.25) {
      appEvents.publish({
        type: AppEvents.alertWarning.name,
        payload: [privateSettingsNotFound],
      });
      setIsVersionFound(false);
      return {
        settings: {} as SignatureFormDTO,
      };
    }

    if (version.raw < 4.25) {
      return {
        settings: {} as SignatureFormDTO,
      };
    }

    const settingsPrivate = settingsPrivateSerie[0]?.fields[1]?.values?.get(0);

    if (settingsPrivate?.length !== PRIVATE_SETTINGS_LENGTH) {
      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [`${invalidPrivateSettings}`],
      });
      setIsVersionFound(false);
      return {
        settings: {} as SignatureFormDTO,
        receptionDate: '',
      };
    }

    const decodedSettings = decodePrivateSettings(settingsPrivate);

    return {
      settings: decodedSettings,
    };
  }, [panelData.state, decodePrivateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const { settings } = React.useMemo(() => getSettings(), [getSettings]);
  return { settings };
};
