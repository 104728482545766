import { getProcessedDataFrames, AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { AppContext } from 'components/SimplePanel';
import React from 'react';
import { PublicFormDTO, PublicSettingsProps } from 'types/form';
import { PUBLIC_SETTINGS, PUBLIC_SETTINGS_LENGTH } from 'utils/constants';
import { decodeToUint32 } from 'utils/decode';
import { scheduling_settings_model, settingsIndicators, SIOrderedKeys, SI_ORDERED } from 'utils/settings_models';

export const useSettingsPublic = (setIsVersionFound: (value: boolean) => void) => {
  const { panelData, dico } = React.useContext(AppContext);
  const { invalidPublicSettings, publicSettingsNotFound } = dico?.dico || {};

  const decodePublicSettings = (settingsValue: string) => {
    // activation bmask uses 4B === 8 chars
    let tmp = decodeToUint32(settingsValue.substring(0, 8));

    // the rest is just values
    const values = settingsValue.substring(8, settingsValue.length);
    let i = 0;
    let publicSettings: PublicFormDTO = {
      ambient_periodicity: 0,
      ambient_hours: 0,
      ambient_minutes: 0,
      ambient_seconds: 0,
      prediction_periodicity: 0,
      prediction_hours: 0,
      prediction_minutes: 0,
      prediction_seconds: 0,
      introspection_periodicity: 0,
      introspection_hours: 0,
      introspection_minutes: 0,
      introspection_seconds: 0,
    };

    for (const [publicSetting] of Object.entries(scheduling_settings_model)) {
      tmp = decodeToUint32(values.substring(i, i + 4));
      i += 4;
      let SEC_OVERHEAD = tmp % 6;
      if (publicSetting === 'introspection') {
        SEC_OVERHEAD = 0;
      }

      publicSettings[`${publicSetting}_periodicity` as keyof PublicFormDTO] = tmp - SEC_OVERHEAD;
      const s = publicSettings[`${publicSetting}_periodicity` as keyof PublicFormDTO] * 10;
      const r = s % 3600;
      publicSettings[`${publicSetting}_hours` as keyof PublicFormDTO] = Math.floor((s - r) / 3600);
      publicSettings[`${publicSetting}_seconds` as keyof PublicFormDTO] = r % 60;
      publicSettings[`${publicSetting}_minutes` as keyof PublicFormDTO] = Math.floor(
        (r - publicSettings[`${publicSetting}_seconds` as keyof PublicFormDTO]) / 60
      );
    }
    return publicSettings;
  };

  const decodeActivationBitMask = (bmask: number) => {
    const decodedSettingsIndicators = {
      ...settingsIndicators,
    };
    for (const elt of SI_ORDERED) {
      decodedSettingsIndicators[elt as SIOrderedKeys].enabled =
        bmask & (1 << settingsIndicators[elt as SIOrderedKeys].bitPos) ? true : false;
    }
    return decodedSettingsIndicators;
  };

  const getPublicSettings = React.useCallback(() => {
    const data = getProcessedDataFrames(panelData?.series);
    const settingsPublicSerie = data.filter((d) => d.name === PUBLIC_SETTINGS);
    const appEvents = getAppEvents();

    if (!settingsPublicSerie?.length) {
      setIsVersionFound(false);
      return;
    }

    const settingsPublic = settingsPublicSerie[0]?.fields[1]?.values?.get(0);
    if (!settingsPublic) {
      appEvents.publish({
        type: AppEvents.alertWarning.name,
        payload: [publicSettingsNotFound],
      });
      setIsVersionFound(false);
      return;
    }

    if (settingsPublic?.length !== PUBLIC_SETTINGS_LENGTH) {
      // chars mismatch to current version.It does probably belong to a previous settings version
      appEvents.publish({
        type: AppEvents.alertWarning.name,
        payload: [invalidPublicSettings],
      });
      setIsVersionFound(false);
      return;
    }

    const decodedSettings = decodePublicSettings(settingsPublic);

    const activ = settingsPublic.substring(0, 8);
    // rev swap32
    let bmask = parseInt('0x' + activ.match(/../g).reverse().join(''), 16);
    const decodedSettingsIndicators = decodeActivationBitMask(bmask);

    return {
      public: decodedSettings,
      indicators: decodedSettingsIndicators,
    };
  }, [panelData]); // eslint-disable-line react-hooks/exhaustive-deps

  const publicSettings = React.useMemo(() => getPublicSettings(), [getPublicSettings]);
  return publicSettings as PublicSettingsProps;
};
