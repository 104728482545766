import * as React from 'react';
import { IconName } from '@grafana/data';
import {
  ConfirmModal,
  Field,
  Icon,
  InlineField,
  Input,
  RadioButtonGroup,
  Select,
  Text,
  Tooltip,
  useStyles2,
  useTheme2
} from '@grafana/ui';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'components/SimplePanel';
import { CustomCard } from 'components/Shared/CustomCard';
import { getAnalysisProfileOptions, getIsoclassOptions, getProcessFunctionOptions, getRadioButtons } from 'utils/formOptions';
import { TableData } from 'types/state';
import { getSectionsStyles } from './sectionsStyles';
import { NotificationError, NotificationSuccess } from 'components/Shared';
import { cloneDeep } from 'lodash';
import { DashboardProps } from 'types/dashboard';
import { getBackendSrv } from '@grafana/runtime';
import { API_DASHBOARD_BY_UID, URL_POST_DATASOURCE_UID, API_SAVE_JSON, DASHBOARD_JSON_MODEL, DASHBOARD_VALVE_JSON_MODEL, IDENTITY_CARD, INCIDENT_ADVISOR_PANEL, API_QUERY_DASHBOARD } from 'utils/constants';
import { getEnglishValue, getOrientationValues, getUpdatedTemplatingList, injectThresholdInAlertPanel } from 'utils/dashboard';

type IdentityCardProps = {
  rowOriginal: TableData;
  canEdit: boolean;
};

type RadioButton = {
  name: string;
  value: string;
  icon: string;
};

/** 
 * Display of the Identity card 
 * Read and edit
 * Dashboard title, process function, ISO Class, sensor orientation, analysis profile
 */

export const IdentityCard: React.FunctionComponent<IdentityCardProps> = ({ rowOriginal, canEdit }) => {
  const { appState, setAppState } = React.useContext(AppContext);
  const { dico, lang, isoClassifications, dashboards, datasources } = appState;

  const [isEditing, setIsEditing] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [radioButtons, setRadioButtons] = React.useState<RadioButton[]>([]);
  const [form, setForm] = React.useState<TableData['identityCard'] & { dashboardTitle: string; }>();
  const { t } = useTranslation();

  const styles = useStyles2(getSectionsStyles);
  const theme = useTheme2();

  const WRITE_API_URL = `${URL_POST_DATASOURCE_UID}${datasources.dashGen.uid}`;
  const IDENTITY_CARD_ENDPOINT = `${WRITE_API_URL}${IDENTITY_CARD}`;


  const { identityCard, macAddress, dashboardTitle, lengthUnit, dashboardUid, dashboardLang } = rowOriginal;
  const { analysisProfile, processFunction, isoClass, sensorOrientation, customIso } = identityCard;

  const { control, handleSubmit, reset, setValue, setError, clearErrors, watch, getValues, formState: { errors, defaultValues } } = useForm({
    defaultValues: {
      dashboardTitle: dashboardTitle,
      analysisProfile: analysisProfile,
      processFunction: processFunction,
      isoClass: isoClass,
      sensorOrientation: sensorOrientation,
      customIso: customIso,
    },
  });

  const checkDashboardName = async (dashboardTitle: string) => {
    if (!dashboardTitle?.trim()) {
      console.log('No dashboard title!');
      return;
    }
    const searchDashName = await getBackendSrv()
      .get(`${API_QUERY_DASHBOARD}${dashboardTitle?.trim()}`)
      .catch(err => {
        NotificationError(err);
        return;
      });
    const dashGrafana = searchDashName.filter((dashboard: { title: string; }) => dashboard.title === dashboardTitle?.trim());
    return dashGrafana;
  };

  const onSubmit: SubmitHandler<IdentityCardProps['rowOriginal']['identityCard'] & { dashboardTitle: string; }> = async (data) => {
    // Rebuild sensor orientation string
    const orientation = `${radioButtons[0].value} ↓ ${radioButtons[1].value} ⟳`;
    // Check if identity card has been changed 
    if (defaultValues?.analysisProfile === data.analysisProfile
      && defaultValues.isoClass === data.isoClass
      && defaultValues?.customIso?.alarm === data.customIso.alarm
      && defaultValues?.customIso?.trip === data.customIso.trip
      && defaultValues.processFunction === data.processFunction
      && defaultValues.sensorOrientation === orientation
      && defaultValues.dashboardTitle === data.dashboardTitle) {
      NotificationError({ message: t('errors.id_card_not_modified') });
      return;
    }

    // Check if dashboard title already exists
    if (defaultValues?.dashboardTitle !== data.dashboardTitle) {
      const dashboardCheck = await checkDashboardName(data.dashboardTitle);
      if (dashboardCheck?.length) {
        NotificationError({ message: `"${data.dashboardTitle}" ${t('errors.dashboard_exists')}` });
        return;
      }
    }

    if (isEditing) {
      setForm(data);
      setIsModalOpen(true);
    }
  };

  const handleOrientationChange = (v: string, button: RadioButton, buttonIndex: number) => {
    const newState = [...radioButtons];
    newState[buttonIndex] = {
      name: button.name,
      value: v!,
      icon: button.icon,
    };

    setRadioButtons(newState);

    const radioButtonsValues = newState.map((v) => v.value);
    let stringToSend = '';
    const findEmptyString = newState.find((v) => v.value === '');
    if (findEmptyString === undefined) {
      stringToSend = `${radioButtonsValues[0]} ↓ ${radioButtonsValues[1]} ⟳`;
    }
    setValue('sensorOrientation', stringToSend);
  };

  const handleDashboardTitleChange = (value: string) => {
    if (!value) {
      setError('dashboardTitle', {
        type: 'custom',
        message: t('errors.required_dashboard_title'),
      });
    } else {
      clearErrors('dashboardTitle');
    }

    setValue('dashboardTitle', value);
  };

  const handleCustomIsoChange = (value: string, valueType: 'alarm' | 'trip') => {
    setValue(`customIso.${valueType}`, value);
    clearErrors(`customIso.${valueType}`);

    switch (valueType) {
      case 'alarm': {
        if (!value) {
          setError('customIso.alarm', { type: 'custom', message: t('errors.custom_iso.alarm_required') });
          break;
        }
        if (parseFloat(value) < 0) {
          setError('customIso.alarm', { type: 'custom', message: t('errors.custom_iso.positive_value') });
          break;
        }

        if (parseFloat(value) > 100) {
          setError('customIso.alarm', { type: 'custom', message: t('errors.custom_iso.value_inferior_to_100') });
          break;
        }

        if (parseFloat(value) >= parseFloat(getValues('customIso.trip'))) {
          setError('customIso.alarm', { type: 'custom', message: t('errors.custom_iso.alarm_inferior_to_trip') });
          break;
        }

        if (parseFloat(value) <= parseFloat(getValues('customIso.trip'))) {
          clearErrors('customIso.trip');
          break;
        }
      }

      case 'trip': {
        if (!value) {
          setError('customIso.trip', { type: 'custom', message: t('errors.custom_iso.trip_required') });
          break;
        }

        if (parseFloat(value) < 0) {
          setError('customIso.trip', { type: 'custom', message: t('errors.custom_iso.positive_value') });
          break;
        }

        if (parseFloat(value) <= parseFloat(getValues('customIso.alarm'))) {
          setError('customIso.trip', { type: 'custom', message: t('errors.custom_iso.trip_superior_to_alarm') });
          break;
        }

        if (parseFloat(value) > 100) {
          setError('customIso.trip', { type: 'custom', message: t('errors.custom_iso.value_inferior_to_100') });
          break;
        }

        if (parseFloat(value) >= parseFloat(getValues('customIso.alarm'))) {
          clearErrors('customIso.alarm');
          break;
        }
      }
      default:
        break;
    }
  };

  const handleISOClassChange = (value: string) => {
    setValue('isoClass', value);
    if (value !== t('classifications.custom_class') && defaultValues?.isoClass === t('classifications.custom_class')) {
      setValue('customIso', { alarm: '', trip: '' });
    }
  };

  const handleConfirmationUpdate = async () => {
    // Validations
    if (!form?.dashboardTitle) {
      NotificationError({ message: `${t('errors.required')}: ${t('form.dashboard_title')}` });
      return;
    }

    if (!form?.analysisProfile) {
      NotificationError({ message: `${t('errors.required')}: ${t('form.analysis_profile')}` });
      return;
    }

    if (!form?.isoClass) {
      NotificationError({ message: `${t('errors.required')}: ${t('form.iso_class')}` });
      return;
    }

    if (!form?.sensorOrientation) {
      NotificationError({ message: `${t('errors.required')}: ${t('form.sensor_orientation')}` });
      return;
    }

    if (!form?.processFunction) {
      NotificationError({ message: `${t('errors.required')}: ${t('form.process_function')}` });
      return;
    }

    const dashboardIndex = dashboards?.findIndex(dash => dash?.macAddress === macAddress);
    if (dashboardIndex === -1) {
      NotificationError({ message: t('errors.dashboard_not_found_in_list') });
      return;
    }

    let dashboardToUpdate: DashboardProps;

    /** Get the Grafana JSON dashboard to update */
    let jsonToUpdate: any = await getBackendSrv()
      .get(`${API_DASHBOARD_BY_UID}${dashboardUid}`)
      .catch((err) => {
        console.log(err);
        NotificationError(t('errors.json_dashboard_not_found'));
      });

    let folderId = jsonToUpdate?.meta?.folderId;
    const profileHasChanged = form.analysisProfile !== analysisProfile;

    const advisorIndex = jsonToUpdate?.dashboard?.panels?.findIndex(
      (panel: { type: string; }) => panel?.type === INCIDENT_ADVISOR_PANEL
    );

    if (advisorIndex === -1) {
      NotificationError({ message: t('errors.id_card_dashboard_not_found') });
      return;
    }
    // Check if the analysis profile has been changed and if it is or it was valve
    if (profileHasChanged && (form.analysisProfile === t('valve') || analysisProfile === t('valve'))) {
      let newProfileTemplate = await getBackendSrv()
        .get(`${form.analysisProfile === t('valve') ? DASHBOARD_VALVE_JSON_MODEL : DASHBOARD_JSON_MODEL}${lang}.json`)
        .catch(err => console.log(err));
      // Update new JSON
      newProfileTemplate.dashboard.uid = jsonToUpdate.dashboard.uid;
      newProfileTemplate.dashboard.title = form.dashboardTitle?.trim();
      newProfileTemplate.dashboard.version = jsonToUpdate.dashboard.version;
      newProfileTemplate.dashboard.time = jsonToUpdate.dashboard.time;
      newProfileTemplate.dashboard.timezone = jsonToUpdate.dashboard.timezone;
      newProfileTemplate.dashboard.id = jsonToUpdate.dashboard.id;
      newProfileTemplate.dashboard.iteration = jsonToUpdate.dashboard.iteration;
      newProfileTemplate.dashboard.panels[advisorIndex].options =
        jsonToUpdate.dashboard.panels[advisorIndex].options;
      newProfileTemplate.dashboard.tags = jsonToUpdate.dashboard.tags;
      newProfileTemplate.dashboard.schemaVersion = jsonToUpdate.dashboard.schemaVersion;
      newProfileTemplate.dashboard.style = jsonToUpdate.dashboard.style;
      newProfileTemplate.dashboard.refresh = jsonToUpdate.dashboard.refresh;
      newProfileTemplate.dashboard.templating = jsonToUpdate.dashboard.templating;
      // inject datasource in the templates variables (needed for session_id)
      newProfileTemplate.dashboard.templating?.list.map(
        (template: { datasource: { type: string; uid: string; }; }) => {
          if (template?.datasource) {
            if (template.datasource.type === 'mysql') {
              template.datasource.uid = datasources.mysql.uid;
            }
          }
        }
      );

      // Inject datasource in the dashboard (profile changed)
      for (const panel of newProfileTemplate.dashboard.panels) {
        // inject datasources
        if (panel?.datasource?.type === 'mysql') {
          panel.datasource.uid = datasources.mysql.uid;
        }
        if (panel?.datasource?.type === 'influxdb') {
          panel.datasource.uid = datasources.influx.uid;
        }
        // inject mac address
        if (panel?.targets?.length) {
          for (const panelTarget of panel.targets) {
            // Replace '$beacon_selection' by the current mac address
            if (panelTarget?.query) {
              panelTarget.query = panelTarget.query.replace('$beacon_selection', macAddress);
            }
          }
        }

        if (panel.panels?.length) {
          for (const nestedPanel of panel.panels) {
            // inject datasources
            if (nestedPanel?.datasource?.type === 'mysql') {
              nestedPanel.datasource.uid = datasources.mysql.uid;
            }
            if (nestedPanel?.datasource?.type === 'influxdb') {
              nestedPanel.datasource.uid = datasources.influx.uid;
            }

            // inject mac address
            if (nestedPanel?.targets?.length) {
              for (const nestedPanelTarget of nestedPanel.targets) {
                // Replace '$beacon_selection' by the current mac address
                if (nestedPanelTarget?.query) {
                  nestedPanelTarget.query = nestedPanelTarget.query.replace(
                    '$beacon_selection',
                    macAddress
                  );
                }
              }
            }
          }
        }
      }
      newProfileTemplate.folderId = jsonToUpdate?.meta?.folderId;
      folderId = newProfileTemplate.folderId;
      jsonToUpdate = newProfileTemplate;
    }

    // Update dashboard JSON with new data
    dashboardToUpdate = jsonToUpdate;
    dashboardToUpdate.folderId = folderId;
    dashboardToUpdate.overwrite = true;
    dashboardToUpdate.dashboard.title = form.dashboardTitle?.trim();

    const DASHBOARD_TO_UPDATE = dashboardToUpdate.dashboard;

    /** Fill identity card infos JSON in dashboard object */
    const idCardItems = [
      { field: 'class', value: form.isoClass },
      { field: 'machine_name', value: form.dashboardTitle },
      { field: 'analysis_profile', value: form.analysisProfile },
      { field: 'process_function', value: form.processFunction },
      { field: 'sensor_orientation', value: form.sensorOrientation },
      { field: 'customIso', value: [form.customIso.alarm, form.customIso.trip] }
    ] as any;

    let incidentAdvisorPanelIndex = DASHBOARD_TO_UPDATE.panels.findIndex((panel: { type: string; }) => panel.type === INCIDENT_ADVISOR_PANEL);

    const removeOldValues = DASHBOARD_TO_UPDATE.panels[incidentAdvisorPanelIndex]?.idCardElements
      .filter((element: { field: string; }) => element.field !== 'class')
      .filter((element: { field: string; }) => element.field !== 'machine_name')
      .filter((element: { field: string; }) => element.field !== 'analysis_profile')
      .filter((element: { field: string; }) => element.field !== 'process_function')
      .filter((element: { field: string; }) => element.field !== 'sensor_orientation')
      .filter((element: { field: string; }) => element.field !== 'customIso');

    DASHBOARD_TO_UPDATE.panels[incidentAdvisorPanelIndex].idCardElements = [...idCardItems, ...removeOldValues];

    /** Inject threshold in Vibration Severity alert panel, depending on current power class. */
    if (form.isoClass !== defaultValues?.isoClass
      || ((defaultValues?.customIso?.alarm !== form.customIso.alarm)
        || (defaultValues?.customIso?.trip !== form.customIso.trip))) {
      injectThresholdInAlertPanel(
        idCardItems,
        DASHBOARD_TO_UPDATE,
        lengthUnit,
        form.customIso,
        isoClassifications
      );
    }

    /** Update grafana templates : 'X', 'Y', 'Z' */
    if (form.sensorOrientation !== defaultValues?.sensorOrientation) {
      const orientationValues = getOrientationValues(form.sensorOrientation);
      const templatesCopy = getUpdatedTemplatingList(dashboardToUpdate, orientationValues);
      DASHBOARD_TO_UPDATE.templating = { list: templatesCopy };
    }

    const payloadForDashboardUpdate = {
      mac_address: macAddress,
      orientation: form.sensorOrientation,
      process_function: form.processFunction,
      machine_class_longname: form.isoClass,
      machine_name: form.dashboardTitle?.trim(),
      analysis_profile: form.analysisProfile
    };

    if (lang !== 'EN') {
      payloadForDashboardUpdate.process_function = getEnglishValue(form.processFunction, 'process_function');
      payloadForDashboardUpdate.machine_class_longname = getEnglishValue(form.isoClass, 'class');
      payloadForDashboardUpdate.analysis_profile = getEnglishValue(form.analysisProfile, 'analysis_profile') as "Valve" | "Rotating" | "Non-rotating";
    }

    const newState = cloneDeep(appState);
    // to be done in 'then' chain
    newState.dashboards[dashboardIndex].dashboardTitle = form?.dashboardTitle;
    newState.dashboards[dashboardIndex].identityCard.analysisProfile = form?.analysisProfile as "Valve" | "Rotating" | "Non-rotating";
    newState.dashboards[dashboardIndex].identityCard.processFunction = form.processFunction;
    newState.dashboards[dashboardIndex].identityCard.sensorOrientation = form.sensorOrientation;
    newState.dashboards[dashboardIndex].identityCard.isoClass = form.isoClass;
    newState.dashboards[dashboardIndex].identityCard.customIso = form.customIso;

    /** Update identity_card in mysql */
    await getBackendSrv()
      .put(IDENTITY_CARD_ENDPOINT, payloadForDashboardUpdate, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        /** Post updated dashboard to Grafana API */
        await getBackendSrv()
          .post(API_SAVE_JSON, dashboardToUpdate)
          .catch((err: any) => {
            console.log(err);
            NotificationError({ message: t('errors.while_saving_dashboard') });
          });
      })
      .then(() => {
        setAppState(newState);
        setIsModalOpen(false);
        setIsEditing(false);
        NotificationSuccess(t('success.identity_card_updated'));
      }).
      catch((err) => {
        console.log(err);
        setIsModalOpen(false);
        setIsEditing(false);
        NotificationError(err);
      });
  };

  React.useEffect(() => {
    if (!isEditing) {
      reset({ ...identityCard, dashboardTitle: dashboardTitle });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  React.useEffect(() => {
    if (identityCard?.sensorOrientation) {
      const finalValues = identityCard?.sensorOrientation.split(' ').filter((str) => str !== '' || str !== undefined);
      setRadioButtons([
        { name: 'vertical', value: finalValues[0], icon: 'arrow-down' },
        { name: 'axial', value: finalValues[2], icon: 'sync' },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container} style={{ border: canEdit ? `1px solid ${theme.colors.border.weak}` : '' }}>
      <CustomCard
        cardTitle={t('identity_card')}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        canEdit={canEdit}
        disabled={!!errors.dashboardTitle}
      >
        {/** Readonly view */}
        {!isEditing && <>
          <Field label='' description={t('form.dashboard_title')}><Text>{dashboardTitle}</Text></Field>
          <Field label='' description={t('form.analysis_profile')}><Text>{analysisProfile}</Text></Field>
          <Field label='' description={t('form.process_function')}><Text>{processFunction}</Text></Field>
          {analysisProfile !== t('valve') && <Field label='' description={t('form.iso_class')}><Text>{isoClass}</Text></Field>}
          <Field label='' description={t('form.sensor_orientation')}><Text>{sensorOrientation}</Text></Field>
        </>}
        {/** Editing view */}
        {isEditing && <>
          {/** Dashboard title */}
          <Field
            label=''
            description={t('form.dashboard_title')}
            htmlFor='dashboardTitle'
            invalid={!!errors.dashboardTitle}
            error={errors?.dashboardTitle?.message}
          >
            <Controller
              name="dashboardTitle"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  id='dashboardTitle'
                  placeholder={t('place_holders.enter_dashboard_title')}
                  type={'string'}
                  onChange={(e) => handleDashboardTitleChange(e.currentTarget.value)}
                />
              )}
            />
          </Field>
          {/* Analysis profile */}
          <Field label='' description={t('form.analysis_profile')} htmlFor='analysisProfile'>
            <Controller
              name="analysisProfile"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  options={getAnalysisProfileOptions(dashboardLang?.toLowerCase())}
                  onChange={(e) => {
                    e.value && setValue('analysisProfile', e.value as TableData['identityCard']['analysisProfile']);
                    // to be translated
                    setValue('processFunction', t('valve'));
                  }}
                  id='analysisProfile'
                />
              )}
            />
          </Field>
          {/* Process function */}
          <Field label='' description={t('form.process_function')}>
            <Controller
              name="processFunction"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={getProcessFunctionOptions(dashboardLang?.toLowerCase(), defaultValues?.processFunction)}
                  onChange={(e) => {
                    e.value && setValue('processFunction', e.value);
                  }}
                />
              )}
            />
          </Field>
          {/* ISO Class */}
          {watch('analysisProfile') !== t('valve') &&
            <Field label='' description={t('form.iso_class')} htmlFor='isoClass'>
              <Controller
                name="isoClass"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    options={getIsoclassOptions(dico, dashboardLang?.toUpperCase(), isoClassifications, lengthUnit)}
                    onChange={(e) => handleISOClassChange(e.value)}
                    id='isoClass'
                  />
                )}
              />
            </Field>}
          {watch('isoClass') === t('classifications.custom_class') && <>
            <div style={{ display: 'flex', marginTop: '8px', flexDirection: 'column', alignItems: 'flex-end' }}>
              <InlineField
                label='Alarm'
                tooltip={t('classifications.alarm_definition')}
                invalid={!!errors.customIso?.alarm}
                error={errors?.customIso?.alarm?.message}
                labelWidth={9}
              >
                <Controller
                  control={control}
                  name='customIso.alarm'
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      type={'number'}
                      {...field}
                      onChange={(e) => handleCustomIsoChange(e.currentTarget.value, 'alarm')}
                      width={16}
                      suffix={lengthUnit === 'inch' ? 'in/s' : 'mm/s'}
                      placeholder="0"
                      min={0}
                      max={100}
                      step='any'
                    />)}
                />
              </InlineField>
              <InlineField
                label='Trip'
                tooltip={t('classifications.trip_definition')}
                invalid={!!errors.customIso?.trip}
                error={errors?.customIso?.trip?.message}
                labelWidth={9}
              >
                <Controller
                  control={control}
                  name='customIso.trip'
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      width={16}
                      type={'number'}
                      {...field}
                      onChange={(e) => handleCustomIsoChange(e.currentTarget.value, 'trip')}
                      suffix={lengthUnit === 'inch' ? 'in/s' : 'mm/s'}
                      placeholder="0"
                      min={0}
                      max={100}
                      step='any'
                    />)}
                />
              </InlineField>
            </div>
          </>}
          {/* Sensor orientation */}
          <Field label='' description={t('form.sensor_orientation')}>
            <Controller
              name="sensorOrientation"
              control={control}
              render={({ field }) =>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {radioButtons.map((button, buttonIndex) => {
                    return (
                      <div key={button.name}>
                        <Tooltip content={button.name} placement="bottom">
                          <Icon name={button.icon as IconName} size="md" className={styles.icon} />
                        </Tooltip>
                        <RadioButtonGroup
                          {...field}
                          options={getRadioButtons()}
                          value={radioButtons[buttonIndex].value}
                          size="sm"
                          onChange={(v) => handleOrientationChange(v, button, buttonIndex)}
                        />
                      </div>
                    );
                  })
                  }
                </div>
              }
            />
          </Field>
        </>}
        {/* Confirmation modal */}
        <ConfirmModal
          isOpen={isModalOpen}
          title={t('modals.id_card.title')}
          body={t('modals.id_card.body')}
          confirmText={t('buttons.update')}
          dismissText={t('buttons.cancel')}
          icon="exclamation-triangle"
          onConfirm={() => handleConfirmationUpdate()}
          onDismiss={() => setIsModalOpen(false)}
        />
      </CustomCard>
    </form >
  );
};
