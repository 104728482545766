import * as React from 'react';
import { Alert, Button, Modal, useStyles2, Text, Field, IconButton } from '@grafana/ui';
import { getBackendSrv } from '@grafana/runtime';
import { CoreRow } from '@tanstack/react-table';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IdentityCard } from './FormSections/IdentityCard';
import { Settings } from './FormSections/Settings';
import { CustomCard, NotificationError, NotificationSuccess } from 'components/Shared';
import { TRAINING_STATUS, TableData } from 'types/state';
import { getStyles } from './cardStyles';
import { AppContext } from 'components/SimplePanel';

import { AI_SESSION_STATUS, TOTAL_POINTS_LIMIT, URL_POST_DATASOURCE_UID } from 'utils/constants';
import { getCountPoints, getLastSessionId } from 'services/getQueries';
import { influxRequest, mysqlRequest } from 'services/datasourcesRequest';
import { dateTimeFormatISO } from '@grafana/data';

/** Component displayed on CONVERGING status */

export interface ParametersProps {
  rowOriginal: CoreRow<TableData>['original'];
}

export const Converging: React.FC<ParametersProps> = ({ rowOriginal }) => {
  const { appState, setAppState } = React.useContext(AppContext);
  const { datasources, dashboards } = appState;
  const { settings, macAddress, training } = rowOriginal;

  const [isConfirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [receivedPoints, setReceivedPoints] = React.useState(0);

  const styles = useStyles2(getStyles);
  const { t } = useTranslation();


  const WRITE_API_URL = `${URL_POST_DATASOURCE_UID}${datasources.dashGen.uid}`;
  const AI_SESSION_STATUS_ENDPOINT = `${WRITE_API_URL}${AI_SESSION_STATUS}`;

  const handleConfirmation = async () => {
    const aiSessionIdQuery = getLastSessionId(macAddress);
    const aiSessionIdResults = await mysqlRequest(datasources.mysql.uid, aiSessionIdQuery)
      .catch((err) => {
        NotificationError(err);
        return;
      });

    if (!aiSessionIdResults) {
      NotificationError({ message: t('errors.last_session_not_found') });
      return;
    }

    if (aiSessionIdResults.length) {
      if (aiSessionIdResults[0][0] !== training.sessionId) {
        NotificationError({ message: t('errors.new_session_created') });
        return;
      }

      const aiPayload = {
        mac_address: macAddress,
        ai_session_id: aiSessionIdResults[0][0],
        new_status: TRAINING_STATUS.Invalidated,
      };

      await getBackendSrv()
        .put(AI_SESSION_STATUS_ENDPOINT, aiPayload, {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          const dashboardIndex = dashboards?.findIndex(dash => dash.macAddress === macAddress);
          if (dashboardIndex !== -1) {
            const newState = cloneDeep(appState);
            newState.dashboards[dashboardIndex].training.status = TRAINING_STATUS.Invalidated;
            newState.dashboards[dashboardIndex].training.sessionId = aiSessionIdResults[0][0];
            newState.dashboards[dashboardIndex].training.performedDate = '';
            newState.dashboards[dashboardIndex].training.convergingEndDate = '';
            newState.dashboards[dashboardIndex].training.type = '';
            setAppState(newState);
            setConfirmModalOpen(false);
            NotificationSuccess(t('success.auto_training_stopped'));
          }
        })
        .catch((err) => {
          NotificationError(err);
          console.log(err);
        });
    }
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
  };

  const countPoints = async () => {
    const countPointsQuery = getCountPoints(macAddress, training.performedDate);
    const countPointsResult = await influxRequest({ name: datasources.influx.name, id: datasources.influx.id }, countPointsQuery);

    if (countPointsResult?.length && countPointsResult[0]?.values?.length) {
      setReceivedPoints(countPointsResult[0].values[0][1]);
    }
  };

  React.useEffect(() => {
    if (datasources.influx.id) {
      countPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasources]);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ maxWidth: '280px', marginRight: '48px' }}>
        {/** Converging  card info */}
        <Alert title={'Converging'} severity={'info'}>
          <div>{t('cards_info.converging.text1')}</div>
          {(receivedPoints <= TOTAL_POINTS_LIMIT) && <div>{t('cards_info.converging.text2')}</div>}
        </Alert>
      </div>
      {/* Identity Card section */}
      {(receivedPoints <= TOTAL_POINTS_LIMIT) && <IdentityCard rowOriginal={rowOriginal} canEdit={true} />}
      {/* Settings */}
      <Settings settings={settings} canEdit={false} macAddress={macAddress} />
      {/* Call to action */}
      <div className={styles.container}>
        <CustomCard cardTitle={t('sections.auto-training.title')} canEdit={false}>
          {training.performedDate &&
            <Field label='' description={t('sections.session.performed_date')}>
              <Text>{dateTimeFormatISO(training.performedDate)}</Text>
            </Field>}
          {<div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Field label='' description={t('sections.auto-training.received_points')}>
              <Text>{receivedPoints} <IconButton size='md' name='sync' tooltip={t('buttons.refresh')} onClick={() => countPoints()} /></Text>
            </Field>
            <div style={{ marginLeft: '8px' }}>
              <IconButton tooltip={t('sections.auto-training.tooltip_received_points')} name='info-circle' />
            </div>
          </div>}
          <Button
            onClick={() => setConfirmModalOpen(true)}
            icon='square-shape'
            variant='secondary'
            fill='outline'
            disabled={!settings.hasSettings}
          >{t('buttons.stop_auto-training')}</Button>
        </CustomCard>
        {/* Confirmation modal */}
        <Modal
          title={`${t('modals.stop_auto-training.title')} ${macAddress}`}
          isOpen={isConfirmModalOpen}
          onDismiss={() => handleCancel()}
        >
          {(receivedPoints <= TOTAL_POINTS_LIMIT) && <div>{t('modals.stop_auto-training.text1')}</div>}
          <div style={{ marginTop: "12px" }}>{t('modals.stop_auto-training.text2')} {macAddress} ?</div>
          <Modal.ButtonRow>
            <Button variant="secondary" fill="outline" onClick={() => handleCancel()}>
              {t('buttons.cancel')}
            </Button>
            <Button variant='destructive' onClick={() => handleConfirmation()}>
              {t('buttons.stop_auto-training')}
            </Button>
          </Modal.ButtonRow>
        </Modal>
      </div>
    </div>
  );
};
