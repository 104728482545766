import { useState, useEffect } from 'react';
import { getDashboardsList } from 'services/getDashboardsList';
import { TableData } from 'types/state';

export const useFetchDashboards = <T,>(dsMysqlUid: string, dsInflux: { id: number, name: string; }, lang: string): { dashboards: TableData[]; loading: boolean; error: string | null; refetch: (mysqlUid: string, influx: { id: number, name: string; }) => Promise<void>; } => {
  const [dashboards, setDashboards] = useState<TableData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchDasboards = (async (mysqlUid: string, influx: { id: number, name: string; }) => {
    try {
      setLoading(true);
      const dashboardList = await getDashboardsList(mysqlUid, { name: influx.name, id: influx.id }, lang)
        .catch((err) =>
          console.log(err)
        );

      if (!dashboardList) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setDashboards(dashboardList);
    } catch (err: any) {
      setError(err.message || 'Error while fetching data dashboards.');
    } finally {
      setLoading(false);
    }
  });

  const refetch = (async (mysqlUid: string, influx: { id: number, name: string; }) => {
    try {
      const dashboardNames = await getDashboardsList(mysqlUid, { name: influx.name, id: influx.id }, lang)
        .catch((err) =>
          console.log(err)
        );

      if (!dashboardNames) {
        return;
      }
      setDashboards(dashboardNames);
    } catch (err: any) {
      setError(err.message || 'Error while fetching data dashboards.');
    }
  });

  useEffect(() => {
    if (dsMysqlUid && dsInflux.name) {
      fetchDasboards(dsMysqlUid, { name: dsInflux.name, id: dsInflux.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsMysqlUid, dsInflux.name]);

  return { dashboards, loading, error, refetch: refetch };
};
