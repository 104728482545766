import { PanelData, DataSourceApi, CurrentUserDTO } from '@grafana/data';
import { IsoClassOptions } from 'hooks/useDictionary';

export interface AppState {
  datasources: {
    influx: DataSourceApi;
    dashGen: DataSourceApi;
    mysql: DataSourceApi;
  };
  dico: IsoClassOptions,
  lang: string;
  panelData: PanelData;
  user: CurrentUserDTO;
  dashboards: TableData[];
  loadingDashboards: boolean;
  isoClassifications: IsoClassificationsProps;
}

export interface TableData {
  folder: string;
  folderId: number;
  dashboardTitle: string;
  dashboardUid: string;
  dashboardLang: string;
  dashboardUrl: string;
  macAddress: string;
  lengthUnit: 'meter' | 'inch';
  identityCard: {
    analysisProfile: 'Rotating' | 'Non-rotating' | 'Valve';
    processFunction: string;
    isoClass: string;
    sensorOrientation: string;
    customIso: {
      alarm: string;
      trip: string;
    };
  };
  settings: {
    receptionDate: string;
    rpm_min: string;
    rpm_max: string;
    hasSettings: boolean;
    rawValue: string;
  };
  training: {
    status: TrainingStatus;
    type: string;
    performedDate: string;
    convergingEndDate: string;
    autoTrainingStatus: AutoTrainingStatus;
    sessionId?: number;
  };
}

export const TRAINING_STATUS = {
  Invalidated: "INVALIDATED",
  Pending: "PENDING",
  Training: "TRAINING",
  NoTraining: "NO TRAINING",
  Ready: "READY",
  Converging: "CONVERGING",
  ErrParam: "ERR_PARAM",
  ErrInternal: "ERR_INTERNAL",
  ErrTrainingData: "ERR_TRAINING_DATA",
  ErrFetchPickle: "ERR_FETCH_PICKLE",
  ErrModelFit: "ERR_MODEL_FIT",
  ErrScore: "ERR_SCORE",
  ErrAdvisor: "ERR_ADVISOR",
  ErrStorePickle: "ERR_STORE_PICKLE"
} as const;

export const AUTO_TRAINING_STATUS = {
  Ok: "OK",
  WarningInd: "WARNING_IND",
  WarningConv: "WARNING_CONV"
} as const;

type EnumValues<T> = T[keyof T];
export type TrainingStatus = EnumValues<typeof TRAINING_STATUS>;
export type AutoTrainingStatus = EnumValues<typeof AUTO_TRAINING_STATUS>;

export const SESSION_TYPE = {
  AutoTrain: 'AUTO_TRAIN',
  Kapacitor: 'KAPACITOR'
} as const;

export type SessionType = EnumValues<typeof SESSION_TYPE>;

export interface ThresholdValues {
  alarm: number;
  trip: number;
}

export enum IsoClasses {
  class1 = 'class1',
  class2 = 'class2',
  class3 = 'class3',
  class4 = 'class4',
  a1 = 'a1',
  a2 = 'a2',
  a3 = 'a3',
  a4 = 'a4',
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
  default = 'default',
}

export enum TranslationLang {
  EN = 'EN',
  FR = 'FR',
}

export type IsoClassTypes = { [Key in IsoClasses]: ThresholdValues };
export type IsoClassTranslationTypes = { [Key in IsoClasses]: string };
export type TranslationsTypes = { [Key in TranslationLang]: IsoClassTranslationTypes };

export interface IsoClassificationsProps {
  vibrationSeverityIsoMms: IsoClassTypes;
  vibrationSeverityIsoIns: IsoClassTypes;
  translations: TranslationsTypes;
}
