import * as React from 'react';
import { css } from '@emotion/css';
import {
  Badge,
  Divider,
  Text,
  useStyles2,
} from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useTranslation } from 'react-i18next';
import { getIconNameStatus } from 'utils/helpers';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    textSeparator: css({
      marginBottom: '16px'
    }),
    paraphSeparator: css({
      marginTop: '8px'
    }),
    wrappedSeparator: css({
      marginBottom: '16px',
      marginTop: '8px'
    })
  };
};

export const StatusesTexts: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const styles = useStyles2(getStyles);

  return (
    <div style={{ padding: '24px', marginBottom: '64px' }}>
      {/** Nominal states */}
      <Text element='h3' weight='medium'>{t('statuses.nominal_states_title')}</Text>
      <Divider />
      {/** PENDING */}
      <Badge color={getIconNameStatus('PENDING').color} text={'PENDING'} />
      <div className={styles.paraphSeparator}>{t('statuses.pending.text1')}</div>
      <div className={styles.textSeparator}>{t('statuses.pending.text2')}</div>
      {/** TRAINING */}
      <Badge color={getIconNameStatus('TRAINING').color} text={'TRAINING'} />
      <div className={styles.paraphSeparator}>{t('statuses.training.text1')}</div>
      <div className={styles.textSeparator}>{t('statuses.training.text2')}</div>
      {/** READY */}
      <Badge color={getIconNameStatus('READY').color} text={'READY'} />
      <div className={styles.wrappedSeparator}>{t('cards_info.ready.text1')}</div>
      {/** INVALIDATED */}
      <Badge color={getIconNameStatus('INVALIDATED').color} text={'INVALIDATED'} />
      <div className={styles.paraphSeparator}>{t('statuses.invalidated.text1')}</div>
      <div>- {t('statuses.invalidated.reason1')}</div>
      <div>- {t('statuses.invalidated.reason2')}</div>
      <div>- {t('statuses.invalidated.reason3')}</div>
      <div className={styles.textSeparator}>- {t('statuses.invalidated.reason4')}</div>
      {/** Errors */}
      <Text element='h3' weight='medium'>{t('statuses.error_title')}</Text>
      <Divider />
      {/** ERR_PARAM */}
      <Badge color={getIconNameStatus('ERR_PARAM').color} text={'ERR_PARAM'} />
      <div className={styles.wrappedSeparator}>{t('statuses.errors.err_param')}</div>
      {/** ERR_INTERNAL */}
      <Badge color={getIconNameStatus('ERR_INTERNAL').color} text={'ERR_INTERNAL'} />
      <div className={styles.wrappedSeparator}>{t('statuses.errors.internal')}</div>
      {/** ERR_TRAINING_DATA */}
      <Badge color={getIconNameStatus('ERR_TRAINING_DATA').color} text={'ERR_TRAINING_DATA'} />
      <div className={styles.wrappedSeparator}>{t('statuses.errors.training_data')}</div>
      {/** ERR_FETCH_PICKLE */}
      <Badge color={getIconNameStatus('ERR_FETCH_PICKLE').color} text={'ERR_FETCH_PICKLE'} />
      <div className={styles.wrappedSeparator}>{t('statuses.errors.fetch_pickle')}</div>
      {/** ERR_MODEL_FIT */}
      <Badge color={getIconNameStatus('ERR_MODEL_FIT').color} text={'ERR_MODEL_FIT'} />
      <div className={styles.wrappedSeparator}>{t('statuses.errors.model_fit')}</div>
      {/** ERR_SCORE / ERR_ADVISOR */}
      <Badge color={getIconNameStatus('ERR_ADVISOR').color} text={'ERR_ADVISOR'} />
      <div className={styles.wrappedSeparator}>{t('statuses.errors.adv_score')}</div>
      {/** ERR_STORE_PICKLE */}
      <Badge color={getIconNameStatus('ERR_STORE_PICKLE').color} text={'ERR_STORE_PICKLE'} />
      <div className={styles.wrappedSeparator}>{t('statuses.errors.store_pickle')}</div>
    </div>
  );
}; 
