import { css } from "@emotion/css";
import { GrafanaTheme2 } from "@grafana/data";

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css({
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }),
    parameters: css({
      display: 'flex',
      width: '100%'
    }),
    container: css({
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginRight: '16px',
      maxWidth: '310px'
    }),
    containerReceivedPoints: css({
      width: '80px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '8px',
    })
  };
};
