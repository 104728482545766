import { css } from "@emotion/css";
import { GrafanaTheme2 } from "@grafana/data";

export const getSectionsStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: '16px',
    maxWidth: '310px'
  }),
  icon: css({
    marginRight: '8px',
  }),
  infoCardSmall: css({
    backgroundColor: `${theme.colors.background.secondary}`,
    maxWidth: '300px',
    borderLeft: `2px solid #3e71cd`,
    padding: '8px',
    marginBottom: '8px',
    fontSize: '12px'
  })
});
