

import { IsoClassificationsProps } from "types/state";
import i18n from '../components/i18n';
import { CALC_FAHRENHEIT, INCIDENT_ADVISOR_PANEL } from "./constants";
import { DashboardProps, Orientation, TemplatesVar } from "types/dashboard";

/**
 * Search for Vibration Severity alert panel and inject alert threshold, depending on power_class.
 * Here, we have to search in dashboard.panels if the row is unfold, otherwise (if fold), search in dashboard.panels.panels
 */
export const injectThresholdInAlertPanel = (
  idCardItems: any,
  DASHBOARD_TO_UPDATE: { panels: any[]; },
  lengthUnit: string,
  customAlert: { alarm: string; trip: string; },
  isoClassifications: IsoClassificationsProps
) => {

  const t = i18n.t;
  const searchCurrentClass = (item: { field: string; }) => item.field === 'class';
  const searchAlertPanel = (panel: { type: string; title: string; }) =>
    panel.type === 'row' && panel.title?.toLowerCase() === t('panels.alert_section_title');

  const currentClassIndex = idCardItems.findIndex(searchCurrentClass);
  const indexAlertPanel = DASHBOARD_TO_UPDATE.panels.findIndex(searchAlertPanel);
  const currentClass = idCardItems[currentClassIndex]?.value;
  const vibratoryThreshold = getVibratoryThreshold(
    currentClass,
    lengthUnit,
    customAlert,
    isoClassifications
  );

  const ALERT_PANEL = DASHBOARD_TO_UPDATE.panels[indexAlertPanel];

  if (indexAlertPanel !== -1) {
    if (ALERT_PANEL.panels.length) {
      // alarm
      const indexVibratoryAlarmPanelRow = ALERT_PANEL.panels.findIndex(
        (panel: { title: string; }) => panel.title.toLowerCase() === t('panels.vibratory_severity_alarm')
      );

      if (indexVibratoryAlarmPanelRow !== -1 && ALERT_PANEL.panels[indexVibratoryAlarmPanelRow]?.alert) {
        ALERT_PANEL.panels[indexVibratoryAlarmPanelRow].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.alarm;
      }

      // trip
      const indexVibratoryTripPanelRow = ALERT_PANEL.panels.findIndex(
        (panel: { title: string; }) => panel.title.toLowerCase() === t('panels.vibratory_severity_trip')
      );

      if (indexVibratoryTripPanelRow !== -1 && ALERT_PANEL.panels[indexVibratoryTripPanelRow]?.alert) {
        ALERT_PANEL.panels[indexVibratoryTripPanelRow].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.trip;
      }

      // trip for valve
      const indexVibratoryPanelRow = ALERT_PANEL.panels.findIndex(
        (panel: { title: string; }) => panel.title.toLowerCase() === t('panels.vibratory_severity')
      );

      if (indexVibratoryPanelRow !== -1 && ALERT_PANEL.panels[indexVibratoryPanelRow]?.alert) {
        ALERT_PANEL.panels[indexVibratoryPanelRow].alert.conditions[0].evaluator.params[0] = vibratoryThreshold.trip;
      }
    } else {
      // alarm
      const indexVibratoryAlarmPanel = DASHBOARD_TO_UPDATE.panels.findIndex(
        (panel: { title: string; }) => panel.title?.toLowerCase() === t('panels.vibratory_severity_alarm')
      );

      if (indexVibratoryAlarmPanel !== -1 && DASHBOARD_TO_UPDATE.panels[indexVibratoryAlarmPanel].alert) {
        DASHBOARD_TO_UPDATE.panels[indexVibratoryAlarmPanel].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.alarm;
      }

      // trip
      const indexVibratoryTripPanel = DASHBOARD_TO_UPDATE.panels.findIndex(
        (panel: { title: string; }) => panel.title?.toLowerCase() === t('panels.vibratory_severity_trip')
      );

      if (indexVibratoryTripPanel !== -1 && DASHBOARD_TO_UPDATE.panels[indexVibratoryTripPanel].alert) {
        DASHBOARD_TO_UPDATE.panels[indexVibratoryTripPanel].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.trip;
      }

      // trip for valve
      const indexVibratoryPanel = DASHBOARD_TO_UPDATE.panels.findIndex(
        (panel: { title: string; }) => panel.title?.toLowerCase() === t('panels.vibratory_severity')
      );

      if (indexVibratoryPanel !== -1 && DASHBOARD_TO_UPDATE.panels[indexVibratoryPanel].alert) {
        DASHBOARD_TO_UPDATE.panels[indexVibratoryPanel].alert.conditions[0].evaluator.params[0] =
          vibratoryThreshold.trip;
      }
    }
  }

  return DASHBOARD_TO_UPDATE;
};


/**
 * Get vibration severity threshold depending on the power_class of the machine.
 */
export const getVibratoryThreshold = (
  currentClass: string,
  lengthUnit: string,
  customThresholds: { alarm: string; trip: string; },
  isoClassifications: IsoClassificationsProps
) => {
  const t = i18n.t;
  const { vibrationSeverityIsoMms, vibrationSeverityIsoIns } = isoClassifications;

  let vibratoryThreshold = { alarm: 0, trip: 0 };

  switch (currentClass) {
    case t('classifications.class1'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class1 : vibrationSeverityIsoIns.class1;
      break;
    }
    case t('classifications.class2'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class2 : vibrationSeverityIsoIns.class2;
      break;
    }
    case t('classifications.class3'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class3 : vibrationSeverityIsoIns.class3;
      break;
    }
    case t('classifications.class4'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class4 : vibrationSeverityIsoIns.class4;
      break;
    }
    // ISO 20816
    case t('classifications.A1'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a1 : vibrationSeverityIsoIns.a1;
      break;
    }
    case t('classifications.A2'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a2 : vibrationSeverityIsoIns.a2;
      break;
    }
    case t('classifications.A3'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a3 : vibrationSeverityIsoIns.a3;
      break;
    }
    case t('classifications.A4'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a4 : vibrationSeverityIsoIns.a4;
      break;
    }
    case t('classifications.B1'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b1 : vibrationSeverityIsoIns.b1;
      break;
    }
    case t('classifications.B2'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b2 : vibrationSeverityIsoIns.b2;
      break;
    }
    case t('classifications.B3'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b3 : vibrationSeverityIsoIns.b3;
      break;
    }
    case t('classifications.B4'): {
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b4 : vibrationSeverityIsoIns.b4;
      break;
    }
    case t('classifications.custom_class'): {
      vibratoryThreshold = {
        alarm: parseFloat(customThresholds.alarm),
        trip: parseFloat(customThresholds.trip),
      };
      break;
    }
    default:
      vibratoryThreshold = lengthUnit === 'meter' ? vibrationSeverityIsoMms.default : vibrationSeverityIsoIns.default;
  }
  return vibratoryThreshold;
};

/** Return the first temperature unit found in panel */
export const getTemperatureUnitInPanels = (panels: any, AMBIENT_TEMPERATURE: string) => {
  let unit = '';
  panels.forEach(
    (panel: { title: string; panels: any[]; collapsed: boolean; fieldConfig: { defaults: { unit: string; }; }; }) => {
      // if the panel is collapsed (the section) then all the plugins are in panel.panels
      if (panel.collapsed && panel.panels.length) {
        const temperaturePanelIndex = panel.panels.findIndex(
          (panelCollapsed: { title: string; }) =>
            panelCollapsed.title?.toUpperCase() === AMBIENT_TEMPERATURE?.toUpperCase()
        );
        if (temperaturePanelIndex !== -1) {
          unit = panel.panels[temperaturePanelIndex]?.fieldConfig.defaults.unit;
        }
      } else {
        if (panel.title?.toUpperCase() === AMBIENT_TEMPERATURE?.toUpperCase()) {
          unit = panel?.fieldConfig.defaults.unit;
        }
      }
    }
  );
  return unit;
};

export const getTemperatureUnit = (unit: string) => {
  let temperatureUnit = '';

  switch (unit) {
    case 'celsius':
      temperatureUnit = 'Celsius (°C)';
      break;
    case 'fahrenheit':
      temperatureUnit = 'Fahrenheit (°F)';
      break;
    case 'Fahrenheit (°F)':
      temperatureUnit = 'fahrenheit';
      break;
    case 'Celsius (°C)':
      temperatureUnit = 'celsius';
      break;
    default:
      break;
  }
  return temperatureUnit;
};

export const updateTemperatureUnitPanels = (
  dashboard: any,
  temperatureUnit: string,
  AMBIENT_TEMPERATURE: string,
  SURFACE_TEMPERATURE: string,
  ALIAS_AMBIENT_TEMP: string
) => {
  if (!dashboard) {
    return;
  }
  /** Inject units (temperature) */
  let dashboardToUpdateTempUnit = getTemperatureUnitInPanels(dashboard.panels, AMBIENT_TEMPERATURE);
  let unitTemperatureUserChoice = getTemperatureUnit(temperatureUnit);

  const AMBIENT_TEMPERATURE_TEXT = AMBIENT_TEMPERATURE?.toUpperCase();
  const SURFACE_TEMPERATURE_TEXT = SURFACE_TEMPERATURE?.toUpperCase();

  if (dashboardToUpdateTempUnit !== unitTemperatureUserChoice) {
    dashboard.panels?.forEach(
      (panel: {
        type: string;
        targets: any[];
        panels: any[];
        collapsed: boolean;
        title: string;
        fieldConfig: { defaults: { unit: string; max: number | null; }; };
      }) => {
        if (panel.type === INCIDENT_ADVISOR_PANEL) {
          panel.targets?.forEach((target: { alias: string; query: string; }) => {
            /** Update Anomaly detector temperature widget */
            if (target?.alias?.toUpperCase() === ALIAS_AMBIENT_TEMP?.toUpperCase()) {
              if (unitTemperatureUserChoice === 'fahrenheit') {
                if (!target.query?.includes(CALC_FAHRENHEIT)) {
                  target.query = buildNewQuery(target.query, 'FROM', `${CALC_FAHRENHEIT} FROM`);
                }
              } else {
                target.query = target.query.replace(`${CALC_FAHRENHEIT} `, '');
              }
            }
          });
        }

        if (panel?.collapsed && panel.panels.length) {
          panel.panels.forEach(
            (panelRow: {
              title: string;
              targets: any[];
              fieldConfig: { defaults: { unit: string; max: number | null; }; };
            }) => {
              if (
                panelRow.title?.toUpperCase() === AMBIENT_TEMPERATURE_TEXT ||
                panelRow.title?.toUpperCase() === SURFACE_TEMPERATURE_TEXT
              ) {
                panelRow.fieldConfig.defaults.unit = unitTemperatureUserChoice;
                panelRow.fieldConfig.defaults.max = null;

                if (panelRow.targets) {
                  panelRow.targets.forEach((target: { query: string; }) => {
                    let pattern =
                      dashboardToUpdateTempUnit === 'celsius' && !target.query?.includes(CALC_FAHRENHEIT)
                        ? 'FROM'
                        : CALC_FAHRENHEIT;

                    if (dashboardToUpdateTempUnit === 'celsius' && pattern === CALC_FAHRENHEIT) {
                      target.query = target.query?.replace(CALC_FAHRENHEIT, '');
                      pattern = 'FROM';
                    }
                    // Modify temperature queries
                    const splitQuery = target.query?.split(pattern);
                    const newQuery =
                      unitTemperatureUserChoice === 'celsius'
                        ? `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`
                        : `${splitQuery[0]?.trim()} ${CALC_FAHRENHEIT} FROM ${splitQuery[1]?.trim()}`;
                    target.query = newQuery;
                  });
                }
              }
            }
          );
        } else {
          if (
            panel.title?.toUpperCase() === AMBIENT_TEMPERATURE_TEXT ||
            panel.title?.toUpperCase() === SURFACE_TEMPERATURE_TEXT
          ) {
            panel.fieldConfig.defaults.unit = unitTemperatureUserChoice;
            panel.fieldConfig.defaults.max = null;
            if (panel.targets) {
              panel.targets.forEach((target: { query: string; }) => {
                let pattern =
                  dashboardToUpdateTempUnit === 'celsius' && !target.query?.includes(CALC_FAHRENHEIT)
                    ? 'FROM'
                    : CALC_FAHRENHEIT;

                if (dashboardToUpdateTempUnit === 'celsius' && pattern === CALC_FAHRENHEIT) {
                  target.query = target.query?.replace(CALC_FAHRENHEIT, '');
                  pattern = 'FROM';
                }

                // Modify temperature queries
                const splitQuery = target.query?.split(pattern);
                const newQuery =
                  unitTemperatureUserChoice === 'celsius'
                    ? `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`
                    : `${splitQuery[0]?.trim()} ${CALC_FAHRENHEIT} FROM ${splitQuery[1]?.trim()}`;
                target.query = newQuery;
              });
            }
          }
        }
      }
    );
  }
};

export const buildNewQuery = (query: string, splitElement: string, stringToAdd: string) => {
  const splitQuery = query.split(splitElement);
  const newQuery = stringToAdd
    ? `${splitQuery[0]?.trim()} ${stringToAdd?.trim()} ${splitQuery[1]?.trim()}`
    : `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`;
  return newQuery;
};

export const getUpdatedTemplatingList = (
  dashboardToUpdate: DashboardProps,
  orientationValues: Orientation,
) => {
  // Keep all templates variables except 'imageFile', 'X', 'Y', 'Z' and length unit
  const templatesCopy = dashboardToUpdate.dashboard.templating.list
    .filter((template) => template.name !== TemplatesVar.X)
    .filter((template) => template.name !== TemplatesVar.Y)
    .filter((template) => template.name !== TemplatesVar.Z);

  // Update template var list with orientation values and 'imageFile'
  const templatesToInsert = [
    { name: TemplatesVar.X, value: orientationValues['X'].value },
    { name: TemplatesVar.Y, value: orientationValues['Y'].value },
    { name: TemplatesVar.Z, value: orientationValues['Z'].value },
  ];

  templatesToInsert.map((template) => {
    templatesCopy.push({
      hide: 2,
      name: template.name,
      query: template.value,
      skipUrlSync: false,
      type: 'constant',
    });
  });
  return templatesCopy;
};

/**
 * Transpose X, Y, Z to 'Vertical', 'Axial', 'Horizontal' or 'Axial-vertical'
 */
export const getOrientationValues = (formOrientation: string) => {
  const orientationObj: Orientation = {
    X: { value: 'X', increment: 0 },
    Y: { value: 'Y', increment: 0 },
    Z: { value: 'Z', increment: 0 },
  };

  const splitValue = formOrientation.split(' ');

  switch (splitValue[0]) {
    case 'X':
      orientationObj.X.value = 'Vertical';
      orientationObj.X.increment += 1;
      break;

    case 'Y':
      orientationObj.Y.value = 'Vertical';
      orientationObj.Y.increment += 1;
      break;

    case 'Z':
      orientationObj.Z.value = 'Vertical';
      orientationObj.Z.increment += 1;
      break;
  }

  switch (splitValue[2]) {
    case 'X':
      orientationObj.X.value = 'Axial';
      if (orientationObj.X.increment > 0) {
        orientationObj.X.value = 'Axial - Vertical';
      }
      orientationObj.X.increment += 1;
      break;

    case 'Y':
      orientationObj.Y.value = 'Axial';
      if (orientationObj.Y.increment > 0) {
        orientationObj.Y.value = 'Axial - Vertical';
      }
      orientationObj.Y.increment += 1;
      break;

    case 'Z':
      orientationObj.Z.value = 'Axial';
      if (orientationObj.Z.increment > 0) {
        orientationObj.Z.value = 'Axial - Vertical';
      }
      orientationObj.Z.increment += 1;
      break;
  }

  let countZero = 0;
  for (const [, value] of Object.entries(orientationObj)) {
    if (value.increment === 0) {
      countZero += 1;
    }
  }

  if (countZero === 1) {
    for (const [key, value] of Object.entries(orientationObj)) {
      if (value.increment === 0) {
        // @ts-ignore
        // TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
        orientationObj[key].value = 'Horizontal';
      }
    }
  }
  return orientationObj;
};

export const getEnglishValue = (frenchValue: string, dicoPart: string): string => {
  // Load the French translation keys
  const frResources = i18n.getResourceBundle('fr', 'translation');
  const enResources = i18n.getResourceBundle('en', 'translation');

  if (!frResources || !enResources) {
    console.error('Translation resources are missing.');
    return frenchValue; // Fallback to the original value
  }

  // Find the key corresponding to the French value
  const key = Object.keys(frResources.identity_card_dico[dicoPart]).find(
    (k) => frResources.identity_card_dico[dicoPart][k] === frenchValue
  );

  // Return the English value if key exists, otherwise fallback to the original French value
  return key ? enResources.identity_card_dico[dicoPart][key] : frenchValue;
};

export const getTranslatedValue = (englishValue: string, dicoPart: string): string => {
  // Load the French translation keys
  const frResources = i18n.getResourceBundle('fr', 'translation');
  const enResources = i18n.getResourceBundle('en', 'translation');

  if (!frResources || !enResources) {
    console.error('Translation resources are missing.');
    return englishValue; // Fallback to the original value
  }

  // Find the key corresponding to the French value
  const key = Object.keys(enResources.identity_card_dico[dicoPart]).find(
    (k) => enResources.identity_card_dico[dicoPart][k] === englishValue
  );

  // Return the English value if key exists, otherwise fallback to the original French value
  return key ? frResources.identity_card_dico[dicoPart][key] : englishValue;
};
