import * as React from 'react';
import { css } from '@emotion/css';
import {
  BigValue,
  BigValueColorMode,
  BigValueGraphMode,
  BigValueTextMode,
  Drawer,
  PageToolbar,
  Tab,
  TabsBar,
  Text,
  ToolbarButton,
  useStyles2,
  useTheme2
} from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { StatusesTexts } from './Tabs/StatusesTexts';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    headerContainer: css({
      paddingTop: "8px",
      display: 'flex',
      flexDirection: 'column',
    }),
    subTitle: css({
      padding: '0px 0 16px 16px',
      backgroundColor: `${theme.colors.background.secondary}`
    }),
    countsContainer: css({
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '8px',
      paddingLeft: '8px',
      borderRight: `1px solid ${theme.colors.border.weak}`,
      borderLeft: `1px solid ${theme.colors.border.weak}`
    })
  };
};

interface HeaderProps {
  overview: {
    totalDashboards: number;
    invalidated: number;
    ready: number;
    converging: number;
    pending: number;
    training: number;
    error: number;
    noTraining: number;
  };
}

export const HeaderContainer: React.FunctionComponent<HeaderProps> = ({ overview }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('autoTraining');
  const theme = useTheme2();
  const styles = useStyles2(getStyles);

  const tabs = <TabsBar>
    <Tab label="Auto-training" active={activeTab === 'autoTraining'} onChangeTab={ev => {
      ev?.preventDefault();
      setActiveTab('autoTraining');
    }} />
    <Tab label="Recommendations" active={activeTab === 'recommendations'} onChangeTab={ev => {
      ev?.preventDefault();
      setActiveTab('recommendations');
    }} />
    <Tab label="Session statuses" active={activeTab === 'statuses'} onChangeTab={ev => {
      ev?.preventDefault();
      setActiveTab('statuses');
    }} />
  </TabsBar>;

  const counts = React.useMemo(() => {
    return [
      { title: 'TOTAL DASHBOARDS', color: theme.visualization.getColorByName('gray'), value: overview.totalDashboards },
      { title: 'READY', color: theme.visualization.getColorByName('green'), value: overview.ready },
      { title: 'TRAINING', color: theme.visualization.getColorByName('blue'), value: overview.training },
      { title: 'CONVERGING', color: theme.visualization.getColorByName('blue'), value: overview.converging },
      { title: 'PENDING', color: theme.visualization.getColorByName('blue'), value: overview.pending },
      { title: 'NO TRAINING', color: theme.visualization.getColorByName('orange'), value: overview.noTraining },
      { title: 'INVALIDATED', color: theme.visualization.getColorByName('red'), value: overview.invalidated },
      { title: 'ERROR', color: theme.visualization.getColorByName('red'), value: overview.error },
    ];
  }, [overview, theme.visualization]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '95%' }}>
      <div className={styles.headerContainer}>
        <PageToolbar pageIcon="play" title="AUTO-TRAINING">
          <ToolbarButton icon="document-info" onClick={() => setIsOpen(true)}>Documentation</ToolbarButton>
        </PageToolbar>
        <div className={styles.subTitle}>
          <Text element='h6' color='secondary' weight='light'>{'1 Check identity card > 2 Check RPM > 3 Launch auto-training'}</Text>
        </div>
        {isOpen && <Drawer title='Auto-training documentation' tabs={tabs} onClose={() => setIsOpen(false)}>
          {activeTab === 'autoTraining' && <div>Here are some info about auto-training</div>}
          {activeTab === 'recommendations' && <div>Some recommendations</div>}
          {activeTab === 'statuses' && <div><StatusesTexts /></div>}
        </Drawer>}
      </div>
      <div className={styles.countsContainer}>
        <div style={{ display: 'flex', maxWidth: '1105px', flexWrap: 'wrap' }}>
          {counts?.filter((count) => count.value !== 0)
            ?.map((count => <DisplayValueCount value={count.value} title={count.title} color={count.color} key={count.title} />))}
        </div>
      </div>
    </div>
  );
};

interface DisplayValueProps {
  value: number;
  title: string;
  color: string;
}
const DisplayValueCount: React.FunctionComponent<DisplayValueProps> = ({ value, title, color }) => {
  const theme = useTheme2();

  return (
    <div style={{ marginRight: '8px', marginBottom: '8px' }}>
      <BigValue
        theme={theme}
        width={130}
        height={75}
        colorMode={BigValueColorMode.Background}
        graphMode={BigValueGraphMode.None}
        textMode={BigValueTextMode.ValueAndName}
        disableWideLayout={false}
        value={{
          text: `${value}`,
          numeric: value,
          color: color,
          title: title
        }}
      />
    </div>
  );
};
