import { SelectableValue } from "@grafana/data";
import { IsoClassOptions } from "hooks/useDictionary";
import { IsoClasses, IsoClassificationsProps, TranslationLang } from "types/state";
import i18n from '../components/i18n';

const getOptions = (elements: string[]) => {
  return elements.map((option) => ({ value: option, label: option }));
};

export const getAnalysisProfileOptions = (lang: string) => {
  if (!lang) {
    return [];
  }
  const resources = i18n.getResourceBundle(lang, 'translation');
  const profiles = Object.values(resources.identity_card_dico.analysis_profile) as string[];
  return getOptions(profiles);
};

export const getProcessFunctionOptions = (lang: string, defaultValue: string | undefined) => {
  if (!lang) {
    return [];
  }
  const resources = i18n.getResourceBundle(lang, 'translation');
  const functions = Object.values(resources.identity_card_dico.process_function) as string[];

  if (defaultValue) {
    const valueFound = Object.values(resources.identity_card_dico.process_function).find(value => value === defaultValue);
    if (!valueFound) {
      functions.push(defaultValue);
    }
  }
  return getOptions(functions);
};

export const getIsoclassOptions = (dico: IsoClassOptions, lang: string, isoClassifications: IsoClassificationsProps, lengthUnit: 'meter' | 'inch') => {
  const newOptions = updateDescriptions(dico.options.class.options, lang, isoClassifications, lengthUnit);
  return newOptions;
};

export const getRadioButtons = () => {
  return [
    { label: 'X', value: 'X' },
    { label: 'Y', value: 'Y' },
    { label: 'Z', value: 'Z' },
    { label: '-', value: '-' },
  ];
};

const updateDescriptions = (options: SelectableValue[], lang: string, isoClassifications: IsoClassificationsProps, lengthUnit: 'meter' | 'inch') => {
  if (!options?.length || !lang) {
    return [];
  }
  const findKey = (optionValue: string) => {
    let foundKey = '';
    for (const [key, value] of Object.entries(isoClassifications.translations[`${lang}` as TranslationLang])) {
      if (optionValue === value) {
        foundKey = key;
        return foundKey as IsoClasses;
      }
    }
    return foundKey as IsoClasses;
  };

  const newOptions = options?.map((node) => {
    node?.options?.map((option: SelectableValue) => {
      if (lengthUnit === 'inch') {
        const key = findKey(option.value);
        option.description = `Alarm: ${isoClassifications.vibrationSeverityIsoIns[key].alarm} in/s | Trip: ${isoClassifications.vibrationSeverityIsoIns[key].trip} in/s`;
      }
      if (lengthUnit === 'meter') {
        const key = findKey(option.value);
        option.description = `Alarm: ${isoClassifications.vibrationSeverityIsoMms[key].alarm} mm/s | Trip: ${isoClassifications.vibrationSeverityIsoMms[key].trip} mm/s`;
      }
      return option;
    });
    return node;
  });
  return newOptions;
};
