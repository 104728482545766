import * as React from 'react';
import { getBackendSrv } from '@grafana/runtime';
import { SelectableValue } from '@grafana/data';
import { URL_ISO_JSON } from 'utils/constants';
import { IsoClassificationsProps } from 'types/state';

/**
 * Fetch dictionary {lang}.json
 */

const DICTIONARY_URL = '/public/asystom/asystom-dictionaries/wizard-tool/';

export const useDictionary = (lang: string) => {
  const [isoClassOptions, setIsoClassOptions] = React.useState({} as IsoClassOptions);
  const [isoClassifications, setIsoClassifications] = React.useState({} as IsoClassificationsProps);

  React.useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const responseDico = await getBackendSrv()
          .get(`${DICTIONARY_URL}${lang}.json`, {
            signal: abortController.signal,
          });

        const resultsClassifications = await getBackendSrv()
          .get(URL_ISO_JSON, {
            signal: abortController.signal,
          });

        setIsoClassOptions(responseDico);
        setIsoClassifications(resultsClassifications);
      } catch (error: any) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isoClassOptions, isoClassifications };
};

export interface IsoClassOptions {
  options: {
    class: {
      options: SelectableValue[];
    };
  };
}

